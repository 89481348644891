.Grid {
    display: grid;

    &--twoColumn {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &--twoColumnTablet {
        grid-template-columns: 1fr;
        gap: $GRID_GAP;

        @include breakpoint-tablet-up {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
    &--twoColumnDesktop {
        grid-template-columns: 1fr;
        gap: $GRID_GAP;

        @include breakpoint-desktop-up {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    &--tileGrid {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: $GRID_GAP;

        @include breakpoint-tablet-up {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
        @include breakpoint-desktop-up {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }

    // One column at mobile, two columns at tablet+
    &--twoCard {
        grid-template-columns: 1fr;
        gap: $GRID_GAP;

        @include breakpoint-tablet-up {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    // One column at mobile, two columns at desktop+
    &--twoCardDesktop {
        grid-template-columns: 1fr;
        gap: $GRID_GAP;

        @include breakpoint-desktop-up {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    // One column at mobile, two columns at tablet, 3 columns at desktop+
    &--threeCard {
        grid-template-columns: 1fr;
        gap: $GRID_GAP;

        @include breakpoint-tablet-up {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @include breakpoint-desktop-up {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    // One column at mobile, 3 columns at desktop+
    &--threeCardDesktop {
        grid-template-columns: 1fr;
        gap: $GRID_GAP;

        @include breakpoint-desktop-up {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    // One column at mobile, two columns at tablet, 4 columns at desktop+
    &--fourCard {
        grid-template-columns: 1fr;
        gap: $GRID_GAP;

        @include breakpoint-tablet-up {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @include breakpoint-desktop-up {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }

    &--product {
        grid-template-columns: minmax(0, 1fr);

        @include breakpoint-desktop-up {
            grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
            gap: $PRODUCT_GRID_GAP;
        }

        &-sidebar {
            @include breakpoint-desktop-up {
                grid-row: 1 / span 2;
                grid-column: 2;
            }
        }

        &-splashes {
            position: relative;
            grid-row: 1;
            grid-column: 1;
            @include breakpoint-desktop-up {
                grid-row: 1;
                grid-column: 1;
            }
        }
    }
}
