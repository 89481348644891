/*
@deprecated Should no longer be used
 */
body.lang-zh-hans {
  font-family: "Roboto", "Noto Sans SC", Arial, Helvetica, sans-serif;
  --font-family-body: Roboto, Noto Sans SC, Arial, Helvetica, sans-serif;
  --font-family-title: Roboto, Noto Sans SC, Arial, Helvetica, sans-serif;
  --font-family-form: Roboto, Noto Sans SC, Arial, Helvetica, sans-serif;
  --font-family-button: Roboto, Noto Sans SC, Arial, Helvetica, sans-serif;
}
body.lang-zh-hans h1, body.lang-zh-hans h2, body.lang-zh-hans h3, body.lang-zh-hans h4, body.lang-zh-hans h5, body.lang-zh-hans h6 {
  font-family: "Roboto", "Noto Sans SC", Arial, Helvetica, sans-serif;
}
body.lang-zh-hans textarea, body.lang-zh-hans input {
  font-family: "Roboto", "Noto Sans SC", Arial, Helvetica, sans-serif;
}

body.lang-zh-hant {
  font-family: "Roboto", "Noto Sans TC", Arial, Helvetica, sans-serif;
  --font-family-body: Roboto, Noto Sans TC, Arial, Helvetica, sans-serif;
  --font-family-title: Roboto, Noto Sans TC, Arial, Helvetica, sans-serif;
  --font-family-form: Roboto, Noto Sans TC, Arial, Helvetica, sans-serif;
  --font-family-button: Roboto, Noto Sans TC, Arial, Helvetica, sans-serif;
}
body.lang-zh-hant h1, body.lang-zh-hant h2, body.lang-zh-hant h3, body.lang-zh-hant h4, body.lang-zh-hant h5, body.lang-zh-hant h6 {
  font-family: "Roboto", "Noto Sans TC", Arial, Helvetica, sans-serif;
}
body.lang-zh-hant textarea, body.lang-zh-hant input {
  font-family: "Roboto", "Noto Sans TC", Arial, Helvetica, sans-serif;
}

body.lang-ja {
  font-family: "Roboto", "Noto Sans JP", Arial, Helvetica, sans-serif;
  --font-family-body: Roboto, Noto Sans JP, Arial, Helvetica, sans-serif;
  --font-family-title: Roboto, Noto Sans JP, Arial, Helvetica, sans-serif;
  --font-family-form: Roboto, Noto Sans JP, Arial, Helvetica, sans-serif;
  --font-family-button: Roboto, Noto Sans JP, Arial, Helvetica, sans-serif;
}
body.lang-ja h1, body.lang-ja h2, body.lang-ja h3, body.lang-ja h4, body.lang-ja h5, body.lang-ja h6 {
  font-family: "Roboto", "Noto Sans JP", Arial, Helvetica, sans-serif;
}
body.lang-ja textarea, body.lang-ja input {
  font-family: "Roboto", "Noto Sans JP", Arial, Helvetica, sans-serif;
}

body.lang-ko {
  font-family: "Roboto", "Noto Sans KR", Arial, Helvetica, sans-serif;
  --font-family-body: Roboto, Noto Sans KR, Arial, Helvetica, sans-serif;
  --font-family-title: Roboto, Noto Sans KR, Arial, Helvetica, sans-serif;
  --font-family-form: Roboto, Noto Sans KR, Arial, Helvetica, sans-serif;
  --font-family-button: Roboto, Noto Sans KR, Arial, Helvetica, sans-serif;
}
body.lang-ko h1, body.lang-ko h2, body.lang-ko h3, body.lang-ko h4, body.lang-ko h5, body.lang-ko h6 {
  font-family: "Roboto", "Noto Sans KR", Arial, Helvetica, sans-serif;
}
body.lang-ko textarea, body.lang-ko input {
  font-family: "Roboto", "Noto Sans KR", Arial, Helvetica, sans-serif;
}

.aspect-wide-bar {
  aspect-ratio: 43/13;
}

.aspect-hd {
  aspect-ratio: 16/9;
}

.aspect-uhd {
  aspect-ratio: 21/10;
}

.aspect-photo {
  aspect-ratio: 3/2;
}

.aspect-square {
  aspect-ratio: 1/1;
}

.aspect-photo-portrait {
  aspect-ratio: 2/3;
}

.space-minimum {
  height: 1px;
}

.space-thin {
  height: 2px;
}

.space-smaller {
  height: 4px;
}

.space-small {
  height: 8px;
}

.space-medium {
  height: 16px;
}

.space-large {
  height: 32px;
}

.space-larger {
  height: 64px;
}

.space-huge {
  height: 128px;
}

.padding-minimum {
  padding: 1px;
}

.padding-thin {
  padding: 2px;
}

.padding-smaller {
  padding: 4px;
}

.padding-small {
  padding: 8px;
}

.padding-medium {
  padding: 16px;
}

.padding-large {
  padding: 32px;
}

.padding-larger {
  padding: 64px;
}

.padding-huge {
  padding: 128px;
}

.padding-horizontal-minimum {
  padding: 0 1px;
}

.padding-horizontal-thin {
  padding: 0 2px;
}

.padding-horizontal-smaller {
  padding: 0 4px;
}

.padding-horizontal-small {
  padding: 0 8px;
}

.padding-horizontal-medium {
  padding: 0 16px;
}

.padding-horizontal-large {
  padding: 0 32px;
}

.padding-horizontal-larger {
  padding: 0 64px;
}

.padding-horizontal-huge {
  padding: 0 128px;
}

.padding-vertical-minimum {
  padding: 1px 0;
}

.padding-vertical-thin {
  padding: 2px 0;
}

.padding-vertical-smaller {
  padding: 4px 0;
}

.padding-vertical-small {
  padding: 8px 0;
}

.padding-vertical-medium {
  padding: 16px 0;
}

.padding-vertical-large {
  padding: 32px 0;
}

.padding-vertical-larger {
  padding: 64px 0;
}

.padding-vertical-huge {
  padding: 128px 0;
}

.margin-minimum {
  margin: 1px;
}

.margin-thin {
  margin: 2px;
}

.margin-smaller {
  margin: 4px;
}

.margin-small {
  margin: 8px;
}

.margin-medium {
  margin: 16px;
}

.margin-large {
  margin: 32px;
}

.margin-larger {
  margin: 64px;
}

.margin-huge {
  margin: 128px;
}

.margin-horizontal-minimum {
  margin: 0 1px;
}

.margin-horizontal-thin {
  margin: 0 2px;
}

.margin-horizontal-smaller {
  margin: 0 4px;
}

.margin-horizontal-small {
  margin: 0 8px;
}

.margin-horizontal-medium {
  margin: 0 16px;
}

.margin-horizontal-large {
  margin: 0 32px;
}

.margin-horizontal-larger {
  margin: 0 64px;
}

.margin-horizontal-huge {
  margin: 0 128px;
}

.margin-vertical-minimum {
  margin: 1px 0;
}

.margin-vertical-thin {
  margin: 2px 0;
}

.margin-vertical-smaller {
  margin: 4px 0;
}

.margin-vertical-small {
  margin: 8px 0;
}

.margin-vertical-medium {
  margin: 16px 0;
}

.margin-vertical-large {
  margin: 32px 0;
}

.margin-vertical-larger {
  margin: 64px 0;
}

.margin-vertical-huge {
  margin: 128px 0;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.inverted {
  filter: invert(1);
}

.inverted-text {
  --color-title: #FFFFFF;
  --color-text: #FFFFFF;
  --color-link: #65C4ED;
  --color-link-hover: #65C4ED;
  color: #FFFFFF;
}

.is-hidden {
  display: none !important;
}

.is-visible {
  display: block !important;
}

.italic, .italics {
  font-style: italic;
}

.scroll-disabled {
  overflow-y: hidden;
}

@media (min-width: 1280px) {
  .inset-content {
    width: 83.3333333333%;
    margin-left: auto;
    margin-right: auto;
  }
}

.analytics-highlight {
  outline: solid 2px #FF0000 !important;
}

.marginBottomAdd {
  margin-bottom: 24px;
}

html {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.6;
}
@media (min-width: 1280px) {
  html {
    font-size: 18px;
  }
}
@media (min-width: 768px) {
  html {
    --page-header-height: 78px;
  }
}
@media (min-width: 1280px) {
  html {
    --page-header-height: 66px;
  }
}

body {
  --font-family-body: Roboto, Arial, Helvetica, sans-serif;
  --font-family-title: Roboto, Arial, Helvetica, sans-serif;
  --font-family-subtitle: Roboto, Arial, Helvetica, sans-serif;
  --font-family-form: Roboto, Arial, Helvetica, sans-serif;
  --font-family-button: Roboto, Arial, Helvetica, sans-serif;
  --color-title: #000000;
  --color-title-inverted: #FFFFFF;
  --color-text: #000000;
  --color-text-inverted: #FFFFFF;
  --page-header-height: 58px;
  --color-link: #136387;
  --color-link-hover: #136387;
  background-color: #FFFFFF;
  color: var(--color-text, #000000);
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.6;
}
@media (min-width: 1280px) {
  body {
    font-size: 18px;
  }
}
@media (min-width: 768px) {
  body {
    --page-header-height: 78px;
  }
}
@media (min-width: 1280px) {
  body {
    --page-header-height: 66px;
  }
}

body, div, p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1, .h1 {
  font-family: var(--font-family-title, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.3;
  text-transform: none;
}
@media (min-width: 768px) {
  h1, .h1 {
    font-size: 3rem;
  }
}

h2, .h2 {
  font-family: var(--font-family-title, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.25;
  text-transform: none;
}
@media (min-width: 768px) {
  h2, .h2 {
    font-size: 2.5rem;
  }
}

h3, .h3 {
  font-family: var(--font-family-title, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 2.0625rem;
  font-weight: 700;
  line-height: 1.25;
  text-transform: none;
}
@media (min-width: 768px) {
  h3, .h3 {
    font-size: 2.056rem;
  }
}

h4, .h4 {
  font-family: var(--font-family-title, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.25;
  text-transform: none;
}
@media (min-width: 768px) {
  h4, .h4 {
    font-size: 1.722rem;
  }
}

h5, .h5 {
  font-family: var(--font-family-subtitle, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 1.4375rem;
  font-weight: 700;
  line-height: 1.25;
  text-transform: none;
}
@media (min-width: 768px) {
  h5, .h5 {
    font-size: 1.389rem;
  }
}

h6, .h6 {
  font-family: var(--font-family-subtitle, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 1.25;
  text-transform: none;
}
@media (min-width: 768px) {
  h6, .h6 {
    font-size: 1.222rem;
  }
}

.h1alt {
  font-family: var(--font-family-title, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.3;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .h1alt {
    font-size: 3rem;
  }
}

.h2alt {
  font-family: var(--font-family-subtitle, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .h2alt {
    font-size: 2.5rem;
  }
}

.h3alt {
  font-family: var(--font-family-subtitle, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 2.0625rem;
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .h3alt {
    font-size: 2.056rem;
  }
}

.h4alt {
  font-family: var(--font-family-subtitle, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .h4alt {
    font-size: 1.722rem;
  }
}

.h5alt {
  font-family: var(--font-family-subtitle, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 1.4375rem;
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .h5alt {
    font-size: 1.389rem;
  }
}

.h6alt {
  font-family: var(--font-family-subtitle, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 1.4;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .h6alt {
    font-size: 1.222rem;
  }
}

.h1hype {
  font-family: var(--font-family-title, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 5.555rem;
  font-weight: 700;
  line-height: 1.3;
  text-transform: uppercase;
}

.h2hype {
  font-family: var(--font-family-title, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 4.611rem;
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase;
}

.h3hype {
  font-family: var(--font-family-title, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 3.833rem;
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase;
}

.h4hype {
  font-family: var(--font-family-title, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 3.222rem;
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase;
}

.h5hype {
  font-family: var(--font-family-title, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 2.666rem;
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase;
}

.h6hype {
  font-family: var(--font-family-title, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 2.222rem;
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
.h1alt, .h2alt, .h3alt, .h4alt, .h5alt, .h6alt {
  color: var(--color-title);
}
h1.is-inverted, h2.is-inverted, h3.is-inverted, h4.is-inverted, h5.is-inverted, h6.is-inverted,
.h1.is-inverted, .h2.is-inverted, .h3.is-inverted, .h4.is-inverted, .h5.is-inverted, .h6.is-inverted,
.h1alt.is-inverted, .h2alt.is-inverted, .h3alt.is-inverted, .h4alt.is-inverted, .h5alt.is-inverted, .h6alt.is-inverted {
  color: var(--color-title-inverted);
}

.color-title {
  color: var(--color-title);
}

.color-text {
  color: var(--color-text);
}

p {
  font-family: var(--font-family-body, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 1rem;
  line-height: 1.6;
}
p.small {
  font-family: var(--font-family-body, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 0.875rem;
  line-height: 1.6;
}
p.large {
  font-family: var(--font-family-body, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 1.1111rem;
  line-height: 1.6;
}

li {
  margin-top: 4px;
  margin-bottom: 4px;
}

ul, ol {
  padding-inline-start: 1rem;
  font-family: var(--font-family-body, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 1rem;
  line-height: 1.6;
}
ul.small, ol.small {
  font-family: var(--font-family-body, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 0.875rem;
  line-height: 1.6;
}
ul.large, ol.large {
  font-family: var(--font-family-body, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 1.1111rem;
  line-height: 1.6;
}

a {
  color: var(--color-link, #136387);
  transition: 250ms;
}
a.is-unlined {
  text-decoration: none;
}
a:visited {
  color: var(--color-link, #136387);
}
a:hover, a:active {
  color: var(--color-link-hover, #136387);
}
a:not(.is-unlined):hover, a:not(.is-unlined):active {
  text-decoration: underline;
}
a.is-inverted {
  --color-link: #65C4ED;
  --color-link-hover: #65C4ED;
}

input[type=button],
input[type=submit] {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: var(--color-button-background, #B30000);
  border: solid 1px var(--color-button-border, transparent);
  border-radius: 4px;
  min-height: 44px;
  padding: 0 24px;
  color: var(--color-button-text, #FFFFFF);
  transition: 250ms;
  font-family: var(--font-family-button, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
input[type=button]:not(:disabled),
input[type=submit]:not(:disabled) {
  cursor: pointer;
}
input[type=button]:hover, input[type=button]:active, input[type=button]:focus,
input[type=submit]:hover,
input[type=submit]:active,
input[type=submit]:focus {
  background: var(--color-button-background-hover, maroon);
  border-color: var(--color-button-border-hover, transparent);
  color: var(--color-button-text-hover, #FFFFFF);
}
input[type=button].is-promo,
input[type=submit].is-promo {
  font-size: 24px;
  min-height: 56px;
  padding: 0 32px;
}
input[type=button].is-fullWidth,
input[type=submit].is-fullWidth {
  display: flex;
  width: 100%;
  text-align: center;
}

input[type=button],
input[type=submit] {
  margin: 0;
}

input[type=button] {
  --color-button-background: #FFFFFF;
  --color-button-border: #C8C8C8;
  --color-button-text: #000000;
  --color-button-background-hover: #000000;
  --color-button-border-hover: #000000;
  --color-button-text-hover: #FFFFFF;
  --filter-button-icon: none;
  --filter-button-icon-hover: invert(1);
}

input[type=submit] {
  --color-button-background: #B30000;
  --color-button-border: transparent;
  --color-button-text: #FFFFFF;
  --color-button-background-hover: maroon;
  --color-button-border-hover: transparent;
  --color-button-text-hover: #FFFFFF;
  --filter-button-icon: invert(1);
  --filter-button-icon-hover: invert(1);
}

.untransformed {
  text-transform: none;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.inverted-text {
  color: var(--color-text-inverted, #FFFFFF);
}
.inverted-text h1, .inverted-text h2, .inverted-text h3, .inverted-text h4, .inverted-text h5, .inverted-text h6,
.inverted-text .h1, .inverted-text .h2, .inverted-text .h3, .inverted-text .h4, .inverted-text .h5, .inverted-text .h6,
.inverted-text .h1alt, .inverted-text .h2alt, .inverted-text .h3alt, .inverted-text .h4alt, .inverted-text .h5alt, .inverted-text .h6alt {
  color: var(--color-text-inverted, #FFFFFF);
}

.header-spacer-fixed {
  height: 58px;
}
@media (min-width: 768px) {
  .header-spacer-fixed {
    height: 78px;
  }
}
@media (min-width: 1280px) {
  .header-spacer-fixed {
    height: 66px;
  }
}

.header-spacer {
  height: var(--page-header-height, 0);
}

@media (min-width: 768px) {
  .header-masthead-spacer {
    height: var(--page-header-height, 0);
  }
}

.AnimateWhenVisible {
  position: relative;
  overflow: visible;
}
.AnimateWhenVisible-content {
  position: relative;
}
.AnimateWhenVisible--fade {
  opacity: 0;
  transition: all 1s;
  pointer-events: none;
}
.AnimateWhenVisible--fade.is-visible {
  opacity: 1;
  pointer-events: all;
}
.AnimateWhenVisible--parallaxFade {
  opacity: 0;
  transform: translateY(2rem);
  transition: all 1s ease-out;
  pointer-events: none;
}
.AnimateWhenVisible--parallaxFade.is-visible {
  opacity: 1;
  transform: translateY(0);
  pointer-events: all;
}
.AnimateWhenVisible--zoomFade {
  opacity: 0;
  transform: scale(0.75);
  transition: all 1s ease-out;
  pointer-events: none;
}
.AnimateWhenVisible--zoomFade.is-visible {
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}

.Card {
  position: relative;
  display: grid;
  gap: 0;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  background: var(--card-background, transparent);
}
.Card-media {
  position: relative;
  display: block;
  aspect-ratio: 16/9;
  padding-bottom: 56.25%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.Card-mediaContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Card-content {
  border: solid 1px var(--card-border-color, #F6F6F6);
  border-top: 0;
  padding: var(--card-content-padding, 16px 16px 24px);
}
.Card--ability .Card-media {
  aspect-ratio: 1;
  background-size: 80%;
  padding-bottom: 100%;
}

@keyframes Carousel-rotating-left-0 {
  from {
    left: 10%;
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(0.5);
  }
  to {
    left: 0;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.3);
  }
}
@keyframes Carousel-rotating-left-1 {
  from {
    left: 30%;
    opacity: 0.75;
    transform: translate(-50%, -50%) scale(0.75);
  }
  to {
    left: 10%;
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(0.5);
  }
}
@keyframes Carousel-rotating-left-2 {
  from {
    left: 50%;
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    left: 30%;
    opacity: 0.75;
    transform: translate(-50%, -50%) scale(0.75);
  }
}
@keyframes Carousel-rotating-left-3 {
  from {
    left: 70%;
    opacity: 0.75;
    transform: translate(-50%, -50%) scale(0.75);
  }
  to {
    left: 50%;
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes Carousel-rotating-left-4 {
  from {
    left: 90%;
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(0.5);
  }
  to {
    left: 70%;
    opacity: 0.75;
    transform: translate(-50%, -50%) scale(0.75);
  }
}
@keyframes Carousel-rotating-left-5 {
  from {
    left: 100%;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.3);
  }
  to {
    left: 90%;
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(0.5);
  }
}
@keyframes Carousel-rotating-right-0 {
  from {
    left: 0;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.3);
  }
  to {
    left: 10%;
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(0.5);
  }
}
@keyframes Carousel-rotating-right-1 {
  from {
    left: 10%;
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(0.5);
  }
  to {
    left: 30%;
    opacity: 0.75;
    transform: translate(-50%, -50%) scale(0.75);
  }
}
@keyframes Carousel-rotating-right-2 {
  from {
    left: 30%;
    opacity: 0.75;
    transform: translate(-50%, -50%) scale(0.75);
  }
  to {
    left: 50%;
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes Carousel-rotating-right-3 {
  from {
    left: 50%;
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    left: 70%;
    opacity: 0.75;
    transform: translate(-50%, -50%) scale(0.75);
  }
}
@keyframes Carousel-rotating-right-4 {
  from {
    left: 70%;
    opacity: 0.75;
    transform: translate(-50%, -50%) scale(0.75);
  }
  to {
    left: 90%;
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(0.5);
  }
}
@keyframes Carousel-rotating-right-5 {
  from {
    left: 90%;
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(0.5);
  }
  to {
    left: 100%;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.3);
  }
}
.Carousel {
  position: relative;
  overflow: visible;
}
.Carousel-wrapper {
  position: relative;
}
.Carousel-content {
  position: relative;
  overflow-y: visible;
}
.Carousel-controls {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  cursor: pointer;
}
.Carousel-prev, .Carousel-next {
  position: absolute;
  top: 50%;
  pointer-events: auto;
  transform: translateY(-50%);
}
.Carousel-prev {
  left: 0;
}
.Carousel-next {
  right: 0;
}
.Carousel--rotating .Carousel-content, .Carousel--single .Carousel-content {
  height: 105.46875px;
}
@media (min-width: 768px) {
  .Carousel--rotating .Carousel-content, .Carousel--single .Carousel-content {
    height: 216px;
  }
}
@media (min-width: 1280px) {
  .Carousel--rotating .Carousel-content, .Carousel--single .Carousel-content {
    height: 360px;
  }
}
@media (min-width: 1920px) {
  .Carousel--rotating .Carousel-content, .Carousel--single .Carousel-content {
    height: 540px;
  }
}
.Carousel--rotating .Carousel-prev, .Carousel--single .Carousel-prev {
  left: auto;
  right: 80%;
}
.Carousel--rotating .Carousel-next, .Carousel--single .Carousel-next {
  left: 80%;
  right: auto;
}
.Carousel--rotating .CarouselSlide, .Carousel--single .CarouselSlide {
  display: none;
  position: absolute;
  top: 50%;
  aspect-ratio: 16/9;
  height: 105.46875px;
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
  overflow: visible;
}
@media (min-width: 768px) {
  .Carousel--rotating .CarouselSlide, .Carousel--single .CarouselSlide {
    height: 216px;
  }
}
@media (min-width: 1280px) {
  .Carousel--rotating .CarouselSlide, .Carousel--single .CarouselSlide {
    height: 360px;
  }
}
@media (min-width: 1920px) {
  .Carousel--rotating .CarouselSlide, .Carousel--single .CarouselSlide {
    height: 540px;
  }
}
.Carousel--rotating .CarouselSlide.is-prev, .Carousel--rotating .CarouselSlide.is-next, .Carousel--single .CarouselSlide.is-prev, .Carousel--single .CarouselSlide.is-next {
  display: block;
  opacity: 0.5;
}
.Carousel--rotating .CarouselSlide.is-prev, .Carousel--single .CarouselSlide.is-prev {
  left: 0;
}
.Carousel--rotating .CarouselSlide.is-next, .Carousel--single .CarouselSlide.is-next {
  left: 100%;
}
.Carousel--rotating .CarouselSlide.is-active, .Carousel--single .CarouselSlide.is-active {
  display: block;
  left: 50%;
  opacity: 1;
}
.Carousel--rotating .CarouselSlide.is-active.is-animatingLeft, .Carousel--single .CarouselSlide.is-active.is-animatingLeft {
  animation: Carousel-rotating-left-2, Carousel-rotating-left-1;
  animation-duration: 250ms, 250ms;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-delay: 0ms, 250ms;
}
.Carousel--rotating .CarouselSlide.is-active.is-animatingRight, .Carousel--single .CarouselSlide.is-active.is-animatingRight {
  animation: Carousel-rotating-right-3, Carousel-rotating-right-4;
  animation-duration: 250ms, 250ms;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-delay: 0ms, 250ms;
}
.Carousel--rotating .CarouselSlide.is-prev, .Carousel--single .CarouselSlide.is-prev {
  display: block;
  left: 10%;
  opacity: 0.5;
  transform: translate(-50%, -50%) scale(0.5);
}
.Carousel--rotating .CarouselSlide.is-prev.is-animatingLeft, .Carousel--single .CarouselSlide.is-prev.is-animatingLeft {
  animation: Carousel-rotating-left-0 250ms forwards ease-in;
}
.Carousel--rotating .CarouselSlide.is-prev.is-animatingLeft.is-wraparound, .Carousel--single .CarouselSlide.is-prev.is-animatingLeft.is-wraparound {
  animation: Carousel-rotating-left-0, Carousel-rotating-left-5;
  animation-duration: 250ms, 250ms;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-delay: 0ms, 250ms;
}
.Carousel--rotating .CarouselSlide.is-prev.is-animatingRight, .Carousel--single .CarouselSlide.is-prev.is-animatingRight {
  animation: Carousel-rotating-right-1, Carousel-rotating-right-2;
  animation-duration: 250ms, 250ms;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-delay: 0ms, 250ms;
}
.Carousel--rotating .CarouselSlide.is-next, .Carousel--single .CarouselSlide.is-next {
  display: block;
  left: 90%;
  opacity: 0.5;
  transform: translate(-50%, -50%) scale(0.5);
}
.Carousel--rotating .CarouselSlide.is-next.is-animatingLeft, .Carousel--single .CarouselSlide.is-next.is-animatingLeft {
  animation: Carousel-rotating-left-4, Carousel-rotating-left-3;
  animation-duration: 250ms, 250ms;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-delay: 0ms, 250ms;
}
.Carousel--rotating .CarouselSlide.is-next.is-animatingRight, .Carousel--single .CarouselSlide.is-next.is-animatingRight {
  animation: Carousel-rotating-right-5 250ms forwards ease-in;
}
.Carousel--rotating .CarouselSlide.is-next.is-animatingRight.is-wraparound, .Carousel--single .CarouselSlide.is-next.is-animatingRight.is-wraparound {
  animation: Carousel-rotating-right-5, Carousel-rotating-right-0;
  animation-duration: 250ms, 250ms;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: ease-in, ease-out;
  animation-delay: 0ms, 250ms;
}
.Carousel--rotating .CarouselSlide.is-beforePrev, .Carousel--single .CarouselSlide.is-beforePrev {
  display: block;
  left: 0;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.3);
}
.Carousel--rotating .CarouselSlide.is-beforePrev.is-animating, .Carousel--single .CarouselSlide.is-beforePrev.is-animating {
  animation: Carousel-rotating-right-0 250ms both ease-out;
  animation-delay: 250ms;
}
.Carousel--rotating .CarouselSlide.is-afterNext, .Carousel--single .CarouselSlide.is-afterNext {
  display: block;
  left: 100%;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.3);
}
.Carousel--rotating .CarouselSlide.is-afterNext.is-animating, .Carousel--single .CarouselSlide.is-afterNext.is-animating {
  animation: Carousel-rotating-left-5 250ms both ease-out;
  animation-delay: 250ms;
}
.Carousel--scrolling .Carousel-wrapper, .Carousel--scrolling-single .Carousel-wrapper {
  overflow-x: hidden;
}
.Carousel--scrolling .Carousel-content, .Carousel--scrolling-single .Carousel-content {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: stretch;
  gap: 0;
}
.Carousel--scrolling .Carousel-prev, .Carousel--scrolling-single .Carousel-prev {
  left: 0;
}
.Carousel--scrolling .Carousel-next, .Carousel--scrolling-single .Carousel-next {
  right: 0;
}
.Carousel--scrolling .CarouselSlide, .Carousel--scrolling-single .CarouselSlide {
  width: 100vw;
}
.Carousel--scrolling .CarouselSlide.is-wrapping, .Carousel--scrolling-single .CarouselSlide.is-wrapping {
  position: absolute;
  top: 0;
  bottom: 0;
}
.Carousel--scrolling.is-wrappingLeft .Carousel-content, .Carousel--scrolling-single.is-wrappingLeft .Carousel-content {
  padding-left: 100vw;
}
.Carousel--scrolling.is-wrappingRight .Carousel-content, .Carousel--scrolling-single.is-wrappingRight .Carousel-content {
  padding-left: 100vw;
  padding-right: 100vw;
}
.Carousel--scrolling:not(.is-animating) .Carousel-wrapper, .Carousel--scrolling-single:not(.is-animating) .Carousel-wrapper {
  scroll-snap-type: x mandatory;
}
.Carousel--scrolling:not(.is-animating) .CarouselSlide, .Carousel--scrolling-single:not(.is-animating) .CarouselSlide {
  scroll-snap-align: start;
}
.Carousel--single .Carousel-controls, .Carousel--scrolling-single .Carousel-controls {
  display: none;
}

.CarouselSlide {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: visible;
}

.CollapsibleActivator {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
}

.CollapsibleActivatorItem {
  position: relative;
  background: var(--collapsible-background, #FFFFFF);
  border: var(--collapsible-border, none);
  color: var(--collapsible-text-color, #000000);
  transition: all 250ms;
  cursor: pointer;
}
.CollapsibleActivatorItem:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 4px;
  background: var(--collapsible-margin-background, transparent);
  transition: all 250ms;
  pointer-events: none;
}
.CollapsibleActivatorItem-title {
  position: relative;
  color: var(--collapsible-title-color, #000000);
  padding: var(--collapsible-title-padding, 24px 24px 24px 32px);
  font-family: var(--font-family-subtitle, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .CollapsibleActivatorItem-title {
    font-size: 1.722rem;
  }
}
.CollapsibleActivatorItem-wrapper {
  position: relative;
  transition: height 250ms;
  overflow: hidden;
}
.CollapsibleActivatorItem-content {
  position: relative;
  padding: var(--collapsible-content-padding, 0 24px 32px 32px);
}
.CollapsibleActivatorItem.is-active:after {
  background: var(--collapsible-margin-background-active, #B30000);
}
.CollapsibleActivatorItem:not(.is-active) .CollapsibleActivatorItem-wrapper {
  height: 0 !important;
}

.CollapsibleFAQ {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  align-items: center;
  padding: 0;
  background: var(--faq-background, #18151C);
  border: var(--faq-border, none);
  border-radius: var(--faq-border-radius, 0);
  color: var(--faq-color-text, #F6F6F6);
  cursor: pointer;
}
.CollapsibleFAQ-header {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  background: var(--faq-background-header, transparent);
}
.CollapsibleFAQ-toggle {
  position: relative;
  width: 16px;
  height: 16px;
  margin: 12px;
}
.CollapsibleFAQ-open:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 2px;
  transform: translateX(-50%);
  background-color: var(--faq-color-toggle, #18151C);
}
.CollapsibleFAQ-open:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  transform: translateY(-50%);
  background-color: var(--faq-color-toggle, #18151C);
}
.CollapsibleFAQ-close {
  display: none;
}
.CollapsibleFAQ-close:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  transform: translateY(-50%);
  background-color: var(--faq-color-toggle-active, #FFFFFF);
}
.CollapsibleFAQ-question {
  font-weight: 700;
  font-size: 12px;
  line-height: 1.5;
  margin: 8px 16px 8px 0;
}
@media (min-width: 768px) {
  .CollapsibleFAQ-question {
    font-size: 14px;
  }
}
@media (min-width: 1280px) {
  .CollapsibleFAQ-question {
    font-size: 16px;
  }
}
.CollapsibleFAQ-answer {
  position: relative;
  overflow: hidden;
  height: 0;
  transition: height 200ms ease-out;
}
.CollapsibleFAQ-answerInner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px;
}
.CollapsibleFAQ.is-open .CollapsibleFAQ-header {
  background: var(--faq-background-header-active, #272727);
  color: var(--faq-color-header-active, #FFFFFF);
}
.CollapsibleFAQ.is-open .CollapsibleFAQ-open {
  display: none;
}
.CollapsibleFAQ.is-open .CollapsibleFAQ-close {
  display: block;
}
.CollapsibleFAQ:hover .CollapsibleFAQ-header {
  background: var(--faq-background-header-active, #272727);
  color: var(--faq-color-header-active, #FFFFFF);
}
.CollapsibleFAQ:hover .CollapsibleFAQ-open:before, .CollapsibleFAQ:hover .CollapsibleFAQ-open:after {
  background-color: var(--faq-color-toggle-active, #FFFFFF);
}

.DeferredBlock {
  opacity: 1;
  transition: opacity 250ms;
}
.DeferredBlock-inner {
  position: relative;
}
.DeferredBlock.is-hidden {
  display: none;
  opacity: 0;
}
.DeferredBlock.is-animating {
  pointer-events: none;
}
.DeferredBlock.is-animating .DeferredBlock-inner {
  position: absolute;
}
.DeferredBlock.is-fading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}
.Filigree {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
.Filigree:before, .Filigree:after {
  content: "";
  position: absolute;
  top: 80px;
  bottom: 0;
  width: 105px;
  background: top/100% 505px no-repeat url(/images/filigree.svg);
  /*
  @include breakpoint-tablet-only {
      width: 56px;
      background-size: 100% $TABLET_HEIGHT;
  }
  @include breakpoint-mobile-only {
      width: 32px;
      background-size: 100% $MOBILE_HEIGHT;
  }
  */
}
.Filigree:before {
  right: max(100% - 105px, 50% + 195.5px);
}
@media (min-width: 768px) {
  .Filigree:before {
    right: max(100% - 105px, 50% + 376px);
  }
}
@media (min-width: 1280px) {
  .Filigree:before {
    right: max(100% - 105px, 50% + 584px);
  }
}
@media (min-width: 1920px) {
  .Filigree:before {
    right: max(100% - 105px, 50% + 840px);
  }
}
.Filigree:after {
  transform: scaleX(-1);
  left: max(100% - 105px, 50% + 195.5px);
}
@media (min-width: 768px) {
  .Filigree:after {
    left: max(100% - 105px, 50% + 376px);
  }
}
@media (min-width: 1280px) {
  .Filigree:after {
    left: max(100% - 105px, 50% + 584px);
  }
}
@media (min-width: 1920px) {
  .Filigree:after {
    left: max(100% - 105px, 50% + 840px);
  }
}
.Filigree--straight:before, .Filigree--straight:after {
  top: 0;
  background-image: url(/images/filigree-straight.svg);
}

.Grid {
  display: grid;
}
.Grid--twoColumn {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.Grid--twoColumnTablet {
  grid-template-columns: 1fr;
  gap: 24px;
}
@media (min-width: 768px) {
  .Grid--twoColumnTablet {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.Grid--twoColumnDesktop {
  grid-template-columns: 1fr;
  gap: 24px;
}
@media (min-width: 1280px) {
  .Grid--twoColumnDesktop {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.Grid--tileGrid {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 24px;
}
@media (min-width: 768px) {
  .Grid--tileGrid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 1280px) {
  .Grid--tileGrid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
.Grid--twoCard {
  grid-template-columns: 1fr;
  gap: 24px;
}
@media (min-width: 768px) {
  .Grid--twoCard {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.Grid--twoCardDesktop {
  grid-template-columns: 1fr;
  gap: 24px;
}
@media (min-width: 1280px) {
  .Grid--twoCardDesktop {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.Grid--threeCard {
  grid-template-columns: 1fr;
  gap: 24px;
}
@media (min-width: 768px) {
  .Grid--threeCard {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 1280px) {
  .Grid--threeCard {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
.Grid--threeCardDesktop {
  grid-template-columns: 1fr;
  gap: 24px;
}
@media (min-width: 1280px) {
  .Grid--threeCardDesktop {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
.Grid--fourCard {
  grid-template-columns: 1fr;
  gap: 24px;
}
@media (min-width: 768px) {
  .Grid--fourCard {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 1280px) {
  .Grid--fourCard {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
.Grid--product {
  grid-template-columns: minmax(0, 1fr);
}
@media (min-width: 1280px) {
  .Grid--product {
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    gap: 24px;
  }
}
@media (min-width: 1280px) {
  .Grid--product-sidebar {
    grid-row: 1/span 2;
    grid-column: 2;
  }
}
.Grid--product-splashes {
  position: relative;
  grid-row: 1;
  grid-column: 1;
}
@media (min-width: 1280px) {
  .Grid--product-splashes {
    grid-row: 1;
    grid-column: 1;
  }
}

.Section {
  position: relative;
  scroll-margin-top: var(--page-header-height);
}
.Section-wrapper {
  position: relative;
  overflow-x: hidden;
}
.Section-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.Section-content {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 767px) {
  .Section-content {
    width: 375px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .Section-content {
    width: 768px;
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .Section-content {
    width: 1280px;
  }
}
@media (min-width: 1920px) {
  .Section-content {
    width: 1920px;
  }
}
.Section--masthead .Section-background {
  overflow: hidden;
  background-color: #000000;
}
@media (min-width: 1920px) {
  .Section--masthead .Section-background {
    background-size: 2200px auto;
  }
  .Section--masthead .Section-background:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 2202px;
    transform: translateX(-50%);
    background: linear-gradient(to right, #000000 0, transparent 201px, transparent 2001px, #000000 100%);
  }
}
@media (min-width: 1920px) {
  .Section--widescreenBackground .Section-background {
    left: 50%;
    right: auto;
    width: 1600px;
    transform: translateX(-50%);
    -webkit-mask-image: linear-gradient(to right, transparent 0, black 10%, black 90%, transparent 100%);
    mask-image: linear-gradient(to right, transparent 0, black 10%, black 90%, transparent 100%);
  }
}
@media (min-width: 1920px) {
  .Section--left .Section-background {
    background-size: 2200px auto;
  }
  .Section--left .Section-background:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 2202px;
    transform: translateX(-50%);
    background: linear-gradient(to right, #FFFFFF 0, transparent 201px, transparent 2001px, #FFFFFF 100%);
  }
}

.SectionBackground {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: center/cover no-repeat;
}
.SectionBackground--masthead {
  background-position: top;
}
@media (max-width: 1279px) {
  .SectionBackground--masthead {
    bottom: auto;
    height: var(--height-masthead-tablet, 60vw);
  }
}
@media (max-width: 767px) {
  .SectionBackground--masthead {
    height: var(--height-masthead-mobile, 80vw);
  }
}
.SectionBackground--top {
  background-position: top;
}
@media (max-width: 1279px) {
  .SectionBackground--top {
    mask-image: linear-gradient(to bottom, black 80%, transparent);
    bottom: auto;
    height: 60vw;
  }
}
@media (max-width: 767px) {
  .SectionBackground--top {
    height: 80vw;
  }
}
.SectionBackground--chevron {
  background-position: top;
  mask-size: 100% 100%;
  mask-repeat: no-repeat;
  mask-position: bottom;
  mask-image: url(/images/mask-chevron-square.svg);
}
@media (min-width: 768px) {
  .SectionBackground--chevron {
    mask-size: 100% auto;
  }
}
@media (min-width: 1280px) {
  .SectionBackground--chevron {
    bottom: auto;
    height: 810px;
  }
}

@media (max-width: 767px) {
  .SectionContent {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .SectionContent {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .SectionContent {
    padding-left: 80px;
    padding-right: 80px;
  }
}
@media (min-width: 1920px) {
  .SectionContent {
    padding-left: 144px;
    padding-right: 144px;
  }
}
.SectionContent p:not(:first-child) {
  margin-block-start: 1.5rem;
}
.SectionContent p:not(:last-child) {
  margin-block-end: 1.5rem;
}
.SectionContent--masthead {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-end;
}
.SectionContent--padded {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .SectionContent--padded {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.SectionContent--singleColumnLeft {
  margin-right: auto;
  max-width: calc(640px + 10vw);
}
@media (min-width: 1920px) {
  .SectionContent--singleColumnLeft {
    max-width: 800px;
  }
}
.SectionContent--singleColumnRight {
  margin-left: auto;
  max-width: calc(640px + 10vw);
}
@media (min-width: 1920px) {
  .SectionContent--singleColumnRight {
    max-width: 800px;
  }
}
.SectionContent--twoColumn {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
}
@media (min-width: 768px) {
  .SectionContent--twoColumn {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.SectionDivider {
  height: 1px;
  background-color: #C8C8C8;
  border: none;
  margin: 0;
  padding: 0;
}

.SectionLayout {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  align-items: center;
}
@media (min-width: 768px) {
  .SectionLayout {
    gap: 24px 32px;
  }
}
@media (min-width: 1280px) {
  .SectionLayout {
    gap: 24px 64px;
  }
}
.SectionLayout-mediaContainer {
  position: relative;
}
.SectionLayout-image {
  width: 100%;
}
.SectionLayout-media {
  position: relative;
}
.SectionLayout-content {
  position: relative;
}
@media (min-width: 768px) {
  .SectionLayout--left {
    grid-template-columns: minmax(0, 3fr) minmax(0, 2fr);
  }
}
@media (min-width: 1280px) {
  .SectionLayout--leftDesktop {
    grid-template-columns: minmax(0, 3fr) minmax(0, 2fr);
  }
}
@media (min-width: 1920px) {
  .SectionLayout--leftWide {
    grid-template-columns: minmax(0, 3fr) minmax(0, 2fr);
  }
}
@media (min-width: 768px) {
  .SectionLayout--right {
    grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
  }
}
@media (min-width: 768px) {
  .SectionLayout--right .SectionLayout-mediaContainer {
    order: 2;
  }
}
@media (min-width: 768px) {
  .SectionLayout--right .SectionLayout-content {
    order: 1;
    padding-left: 28px;
  }
}
@media (min-width: 1280px) {
  .SectionLayout--right .SectionLayout-content {
    padding-left: 56px;
  }
}
@media (min-width: 1280px) {
  .SectionLayout--rightDesktop {
    grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
  }
}
@media (min-width: 1280px) {
  .SectionLayout--rightDesktop .SectionLayout-mediaContainer {
    order: 2;
  }
}
@media (min-width: 1280px) {
  .SectionLayout--rightDesktop .SectionLayout-content {
    order: 1;
    padding-left: 56px;
  }
}
@media (min-width: 1920px) {
  .SectionLayout--rightWide {
    grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
  }
}
@media (min-width: 1920px) {
  .SectionLayout--rightWide .SectionLayout-mediaContainer {
    order: 2;
  }
}
@media (min-width: 1920px) {
  .SectionLayout--rightWide .SectionLayout-content {
    order: 1;
    padding-left: 56px;
  }
}

.Sticky {
  position: relative;
  z-index: 10;
}
.Sticky .Sticky-content {
  position: relative;
}
.Sticky.is-fixed .Sticky-content {
  position: fixed;
  left: 0;
  right: 0;
}

.SubNavigation {
  position: relative;
  text-align: center;
}
.SubNavigation a,
.SubNavigation a:hover,
.SubNavigation a:active {
  text-decoration: none;
}
.SubNavigation-wrapper {
  position: relative;
  background-color: var(--color-subnavigation-background, #000000);
}
.SubNavigation-scroller {
  position: relative;
  scroll-behavior: smooth;
  padding: 0 16px;
}
.SubNavigation-content {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  white-space: nowrap;
  padding: 0;
  margin: 0;
}
.SubNavigation-down {
  display: none;
  position: absolute;
  top: 28px;
  right: 32px;
  filter: invert(1);
  cursor: pointer;
}
.SubNavigation-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.SubNavigation-underline {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: var(--color-subnavigation-underline, #DDC57D);
}
.SubNavigation-underline.is-animated {
  transition: all 250ms;
}
.SubNavigation.is-fixed .SubNavigation-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
@media (min-width: 768px) {
  .SubNavigation.is-collapsed .SubNavigation {
    padding-top: 32px;
  }
}
.SubNavigation.is-collapsed .SubNavigation-content {
  flex-direction: column;
}
.SubNavigation.is-collapsed .SubNavigation-content .SubNavigationItem {
  display: none;
}
.SubNavigation.is-collapsed .SubNavigation-content .SubNavigationItem.is-active {
  display: block;
}
.SubNavigation.is-collapsed .SubNavigation-underline {
  display: none;
}
.SubNavigation.is-collapsed .SubNavigation-down {
  display: block;
}
.SubNavigation.is-collapsed.is-open .SubNavigation-content {
  width: 100%;
}
.SubNavigation.is-collapsed.is-open .SubNavigation-content .SubNavigationItem {
  display: block;
}
.SubNavigation.is-collapsed.is-open .SubNavigation-icon {
  transform: translate(-50%, -50%) rotate(180deg);
}

.SubNavigationDivider {
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
}
.SubNavigationDivider-inner {
  width: 1px;
  height: 1em;
  background-color: var(--color-subnavigation-divider, rgba(255, 255, 255, 0.5));
}

.SubNavigationDropdown {
  position: relative;
  padding: 12px 0;
  margin: 0 8px;
}
.SubNavigationDropdown:hover .SubNavigationDropdown-label {
  background-color: #3F5149;
  border-radius: 4px;
}
.SubNavigationDropdown:hover .SubNavigationDropdown-dropdown {
  display: block;
}
.SubNavigationDropdown-label {
  padding: 4px 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.SubNavigationDropdown-label .SubNavigationItem-tag {
  margin: 0;
}
.SubNavigationDropdown-icon {
  filter: invert(1);
}
.SubNavigationDropdown-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  padding-top: 8px;
}
.SubNavigationDropdown-content {
  margin: 0;
  padding: 24px;
  list-style: none;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: black;
  border-radius: 8px;
}
.SubNavigationDropdown-content .SubNavigationItem {
  display: flex;
  gap: 4px;
}
.SubNavigationDropdown-content .SubNavigationItem-link {
  padding: 0;
  margin: 0;
}
.SubNavigationDropdown.is-collapsed {
  display: none;
  padding: 0;
  margin: 0;
}
.SubNavigationDropdown.is-collapsed .SubNavigationDropdown-icon {
  display: none;
}
.SubNavigationDropdown.is-collapsed.is-open {
  display: block;
  width: 100%;
}
.SubNavigationDropdown.is-collapsed.is-open .SubNavigationDropdown-label {
  padding: 16px;
}
.SubNavigationDropdown.is-collapsed.is-open .SubNavigationDropdown-dropdown {
  display: block;
  position: static;
}
.SubNavigationDropdown.is-collapsed.is-open .SubNavigationDropdown-content {
  background-color: #232B27;
  border-radius: 0;
  padding: 0;
  gap: 0;
}
.SubNavigationDropdown .SubNavigationItem-tag {
  font-weight: 700;
}

.SubNavigationItem {
  position: relative;
  margin: 0;
}
.SubNavigationItem-tag {
  padding: 4px 8px;
  border-radius: 4px;
  margin-left: 16px;
}
.SubNavigationItem-tag--new {
  display: inline-block;
  background-color: #DDC57D;
  color: #000000;
  padding: 8px;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
}
.SubNavigationItem-tag--sale {
  color: #FFFFFF;
  display: inline-flex;
  background: linear-gradient(90deg, #b30000 0%, rgb(163, 0, 90) 100%);
}
.SubNavigationItem-link {
  position: relative;
  display: inline-flex;
  margin: 16px;
  --color-link: transparent;
  --color-link-hover: transparent;
  font-weight: 700;
}
.SubNavigationItem-link:after {
  content: attr(data-label);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  color: var(--color-subnavigation-link, #FFFFFF);
  font-weight: 400;
}
.SubNavigationItem-link:hover:after {
  color: var(--color-subnavigation-link-hover, #DDC57D);
  font-weight: 700;
}
.SubNavigationItem--button {
  display: flex;
  align-items: center;
  margin: 8px;
}
.SubNavigationItem--dropdown.is-collapsed {
  padding: 16px;
}
.SubNavigationItem--dropdown.is-collapsed.is-active {
  background-color: #3F5149;
}
.SubNavigationItem.is-active .SubNavigationItem-link:after {
  font-weight: 700;
}

.TabContainer {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  align-items: center;
  margin-bottom: 36px;
}
@media (min-width: 1280px) {
  .TabContainer {
    margin-bottom: 40px;
  }
}
.TabContainer-compact {
  position: relative;
}
@media (min-width: 1280px) {
  .TabContainer-compact {
    display: none;
  }
}
.TabContainer-dropdown {
  background: var(--color-tab-dropdown-background, #404040);
  border: solid 1px var(--color-tab-dropdown-border, transparent);
  border-radius: 4px;
  height: auto;
  margin: 0;
  padding: 16px;
  font-family: var(--font-family-form, Roboto, Arial, Helvetica, sans-serif);
  font-size: var(--font-size-form, 16px);
  color: var(--color-tab-dropdown-text, #FFFFFF);
}
.TabContainer-tabs {
  position: relative;
  display: none;
  gap: 16px;
}
@media (min-width: 1280px) {
  .TabContainer-tabs {
    display: flex;
    flex-wrap: wrap;
  }
}
.TabContainer.is-labeled {
  grid-template-columns: auto 1fr;
}
.TabContainer--centered .TabContainer-compact {
  text-align: center;
}
@media (min-width: 1280px) {
  .TabContainer--centered .TabContainer-tabs {
    justify-content: center;
  }
}

.Tab {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  background: var(--color-tab-background, #000000);
  border: solid 1px var(--color-tab-border, transparent);
  border-radius: 4px;
  min-height: 44px;
  padding: 0 24px;
  color: transparent;
  transition: 250ms;
  font-family: var(--font-family-tab, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}
.Tab:after {
  content: attr(data-label);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-tab-text, #FFFFFF);
  transition: 250ms;
  font-weight: 400;
}
.Tab.is-active {
  background: var(--color-tab-background-active, #B30000);
  border-color: var(--color-tab-border-active, transparent);
  cursor: default;
}
.Tab.is-active:after {
  color: var(--color-tab-text-active, #FFFFFF);
  font-weight: 700;
}
.Tab:hover {
  background: var(--color-tab-background-hover, #B30000);
  border-color: var(--color-tab-border-hover, transparent);
}
.Tab:hover:after {
  color: var(--color-tab-text-hover, #FFFFFF);
  font-weight: 700;
}

.TabContent {
  display: none;
  position: relative;
}
.TabContent.is-active {
  display: block;
}

.Button {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: var(--color-button-background, #B30000);
  border: solid 1px var(--color-button-border, transparent);
  border-radius: 4px;
  min-height: 44px;
  padding: 0 24px;
  color: var(--color-button-text, #FFFFFF);
  transition: 250ms;
  font-family: var(--font-family-button, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.Button:not(:disabled) {
  cursor: pointer;
}
.Button:hover, .Button:active, .Button:focus {
  background: var(--color-button-background-hover, maroon);
  border-color: var(--color-button-border-hover, transparent);
  color: var(--color-button-text-hover, #FFFFFF);
}
.Button.is-promo {
  font-size: 24px;
  min-height: 56px;
  padding: 0 32px;
}
.Button.is-fullWidth {
  display: flex;
  width: 100%;
  text-align: center;
}
.Button-icon {
  margin-left: -8px;
  margin-right: 8px;
  filter: var(--filter-button-icon, invert(1));
}
.Button-iconAfter {
  margin-left: 8px;
  margin-right: -8px;
  filter: var(--filter-button-icon, invert(1));
}
.Button-label {
  display: inline-block;
}
.Button:hover .Button-icon, .Button:active .Button-icon, .Button:focus .Button-icon {
  filter: var(--filter-button-icon-hover, invert(1));
}
.Button--primary {
  --color-button-background: #B30000;
  --color-button-border: transparent;
  --color-button-text: #FFFFFF;
  --color-button-background-hover: maroon;
  --color-button-border-hover: transparent;
  --color-button-text-hover: #FFFFFF;
  --filter-button-icon: invert(1);
  --filter-button-icon-hover: invert(1);
}
.Button--secondary {
  --color-button-background: #FFFFFF;
  --color-button-border: #C8C8C8;
  --color-button-text: #000000;
  --color-button-background-hover: #000000;
  --color-button-border-hover: #000000;
  --color-button-text-hover: #FFFFFF;
  --filter-button-icon: none;
  --filter-button-icon-hover: invert(1);
}
.Button--invertedSecondary {
  --color-button-background: #000000;
  --color-button-border: #000000;
  --color-button-text: #FFFFFF;
  --color-button-background-hover: #565656;
  --color-button-border-hover: #565656;
  --color-button-text-hover: #FFFFFF;
  --filter-button-icon: invert(1);
  --filter-button-icon-hover: invert(1);
}
.Button--disabled {
  --color-button-background: #C8C8C8;
  --color-button-border: #C8C8C8;
  --color-button-text: #000000;
  --color-button-background-hover: #C8C8C8;
  --color-button-border-hover: #C8C8C8;
  --color-button-text-hover: #000000;
  --filter-button-icon: none;
  --filter-button-icon-hover: none;
  pointer-events: none;
}

.ButtonGroup {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
@media (max-width: 767px) {
  .ButtonGroup {
    flex-flow: column nowrap;
    align-items: stretch;
  }
  .ButtonGroup .Button {
    width: 100%;
  }
}

.Caption {
  position: relative;
  text-align: center;
}
.Caption-image {
  margin: 0;
  padding: 0;
}
.Chart {
  position: relative;
  display: inline-block;
}
.Chart canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.Duviri {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
.Duviri-svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.Icon {
  display: inline-block;
  height: 20px;
  aspect-ratio: 1;
}
.Icon--raster {
  height: 24px;
}
.Icon--videoOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 40%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
  pointer-events: none;
}
.Icon.is-inverted {
  filter: invert(1);
}
.Icon.is-inline {
  vertical-align: text-top;
}

.Lightbox {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 500;
}
.Lightbox-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}
.Lightbox-slides {
  position: relative;
}
.Lightbox-slide {
  position: relative;
  display: none;
  text-align: center;
}
.Lightbox-slide.is-active {
  display: block;
}
.Lightbox-slideImage {
  display: inline-block;
  max-width: 100%;
  height: auto;
  object-fit: contain;
  vertical-align: bottom;
}
.Lightbox-slideLink {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 4px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  line-height: 1;
  --color-link: white;
  --color-link-hover: white;
}
.Lightbox-slideLink .Icon {
  filter: invert(1);
}
.Lightbox-prev, .Lightbox-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 16px;
  z-index: 1;
}
@media (max-width: 767px) {
  .Lightbox-prev, .Lightbox-next {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 8px;
  }
}
.Lightbox-prevIcon, .Lightbox-nextIcon {
  width: 32px;
  height: 32px;
  filter: invert(1);
}
@media (min-width: 1280px) {
  .Lightbox-prevIcon, .Lightbox-nextIcon {
    width: 48px;
    height: 48px;
  }
}
.Lightbox-prev {
  right: 100%;
}
@media (max-width: 767px) {
  .Lightbox-prev {
    transform: translate(25%, -50%);
  }
}
.Lightbox-next {
  left: 100%;
}
@media (max-width: 767px) {
  .Lightbox-next {
    transform: translate(-25%, -50%);
  }
}
.Lightbox-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  cursor: pointer;
}
.Lightbox-closeIcon {
  width: 32px;
  height: 32px;
  filter: invert(1);
}
@media (min-width: 768px) {
  .Lightbox-closeIcon {
    width: 48px;
    height: 48px;
  }
}
.Lightbox.is-active {
  display: block;
}

.Masthead {
  position: relative;
  background-color: var(--masthead-background-color, transparent);
  z-index: 1;
}
.Masthead-background {
  background: center/cover no-repeat var(--masthead-background);
}
@media (max-width: 767px) {
  .Masthead-background {
    height: var(--height-masthead-mobile, 80vw);
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .Masthead-background {
    height: var(--height-masthead-tablet, 60vw);
  }
}
@media (min-width: 1280px) {
  .Masthead-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.Masthead-foreground {
  position: relative;
}
@media (max-width: 767px) {
  .Masthead-foreground {
    margin-top: -64px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .Masthead-foreground {
    margin-top: -160px;
  }
}
.Masthead-overlay {
  position: relative;
}
@media (max-width: 767px) {
  .Masthead-overlay {
    background: linear-gradient(to bottom, transparent 0% 40px, var(--masthead-overlay-color, #000000) 72px);
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .Masthead-overlay {
    background: linear-gradient(to bottom, transparent 0% 56px, var(--masthead-overlay-color, #000000) 88px);
  }
}
.Masthead-content {
  position: relative;
  text-align: center;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .Masthead-content {
    width: 343px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .Masthead-content {
    width: 704px;
  }
}
@media (min-width: 1280px) {
  .Masthead-content {
    width: 1120px;
  }
}
@media (min-width: 1920px) {
  .Masthead-content {
    width: 1632px;
  }
}
.Masthead-inner {
  position: relative;
  text-align: center;
}
.Masthead-logoImage {
  width: 300px;
  vertical-align: bottom;
}
@media (min-width: 768px) {
  .Masthead-logoImage {
    width: 320px;
  }
}
@media (min-width: 1280px) {
  .Masthead-logoImage {
    width: 400px;
  }
}
.Masthead-subtitle {
  color: var(--color-masthead-title, #FFFFFF);
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  border-top: solid 1px var(--color-masthead-border, rgba(255, 255, 255, 0.24));
  border-bottom: solid 1px var(--color-masthead-border, rgba(255, 255, 255, 0.24));
  padding: 16px 0;
}
@media (max-width: 767px) {
  .Masthead-subtitle {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .Masthead-subtitle {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .Masthead-subtitle {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
@media (min-width: 1920px) {
  .Masthead-subtitle {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
.Masthead-buttons {
  position: relative;
}
.Masthead-footer {
  position: relative;
}
@media (min-width: 1280px) {
  .Masthead--standard .Masthead-inner {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    width: 41.6666666667%;
    margin-right: auto;
    height: var(--height-masthead-max, 810px);
  }
}
@media (max-width: 767px) {
  .Masthead--standard .Masthead-overlay {
    background: linear-gradient(to bottom, transparent 0% 40px, var(--masthead-overlay-color, #000000) 72px);
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .Masthead--standard .Masthead-overlay {
    background: linear-gradient(to bottom, transparent 0% 56px, var(--masthead-overlay-color, #000000) 88px);
  }
}
@media (min-width: 1280px) {
  .Masthead--centered .Masthead-inner {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-end;
    width: 41.6666666667%;
    margin-left: auto;
    margin-right: auto;
    height: var(--height-masthead-max, 810px);
    padding-bottom: 24px;
  }
}
@media (max-width: 767px) {
  .Masthead--footer {
    margin-top: 0;
  }
}
@media (min-width: 1280px) {
  .Masthead--footer .Masthead-inner {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    width: 41.6666666667%;
    margin-right: auto;
    height: var(--height-masthead-max, 810px);
  }
}
.Masthead--footer .Masthead-content {
  padding-bottom: 60px;
}
@media (min-width: 1280px) {
  .Masthead--footer .Masthead-content {
    padding-bottom: 80px;
  }
}
@media (max-width: 767px) {
  .Masthead--footerMirrored {
    margin-top: 0;
  }
}
@media (min-width: 1280px) {
  .Masthead--footerMirrored .Masthead-inner {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    width: 41.6666666667%;
    margin-left: auto;
    height: var(--height-masthead-max, 810px);
  }
}
.Masthead--footerMirrored .Masthead-content {
  padding-bottom: 60px;
}
@media (min-width: 1280px) {
  .Masthead--footerMirrored .Masthead-content {
    padding-bottom: 80px;
  }
}
@media (max-width: 767px) {
  .Masthead--footerCentered {
    margin-top: 0;
  }
}
@media (min-width: 1280px) {
  .Masthead--footerCentered .Masthead-inner {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-end;
    width: 41.6666666667%;
    margin-left: auto;
    margin-right: auto;
    height: var(--height-masthead-max, 810px);
    padding-bottom: 24px;
  }
}
.Masthead--footerCentered .Masthead-content {
  padding-bottom: 60px;
}
@media (min-width: 1280px) {
  .Masthead--footerCentered .Masthead-content {
    padding-bottom: 80px;
  }
}

.MobileScroller {
  position: relative;
  overflow-x: hidden;
}
.MobileScroller-inner {
  position: relative;
  overflow-x: auto;
}
.MobileScroller:before, .MobileScroller:after {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 44px;
  z-index: 1;
  pointer-events: none;
}
.MobileScroller.is-fadedLeft:before {
  content: "";
  left: 0;
  background: linear-gradient(to right, #FFFFFF, transparent);
}
.MobileScroller.is-fadedRight:after {
  content: "";
  right: 0;
  background: linear-gradient(to left, #FFFFFF, transparent);
}
@media (max-width: 767px) {
  .MobileScroller--inset {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .MobileScroller--inset {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .MobileScroller--inset {
    padding-left: 80px;
    padding-right: 80px;
  }
}
@media (min-width: 1920px) {
  .MobileScroller--inset {
    padding-left: 144px;
    padding-right: 144px;
  }
}

.MusicLinks {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  gap: 24px;
}
.MusicLinks-column {
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
}
.MusicLinks-heading {
  color: var(--color-text);
  font-family: var(--font-family-subtitle, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 1.4375rem;
  font-weight: 700;
  line-height: 1.25;
  text-transform: none;
}
@media (min-width: 768px) {
  .MusicLinks-heading {
    font-size: 1.389rem;
  }
}

.MusicLink {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  text-decoration: underline;
  --color-link: var(--color-text);
}
.MusicLink-icon {
  width: 25px;
  height: 25px;
  border: 0;
  object-fit: cover;
}
.PackSelector {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
}

.PackSelectorItem {
  position: relative;
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  align-items: center;
  gap: 8px;
  padding: var(--padding-pack-selector, 16px 24px);
  line-height: 1.6;
  font-weight: var(--font-weight-pack-selector, normal);
  color: var(--color-pack-selector-text, #000000);
  background: var(--background-pack-selector, none);
  border-radius: 8px;
  transition: all 250ms;
  cursor: pointer;
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .PackSelectorItem {
    grid-template-columns: minmax(0, 1fr);
  }
}
.PackSelectorItem:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: solid 1px var(--color-pack-selector-border, #000000);
  border-radius: 8px;
  transition: all 250ms;
  pointer-events: none;
}
.PackSelectorItem-bullet {
  display: inline-block;
  width: 18px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: solid 1px var(--color-pack-selector-bullet-border, #000000);
  background: var(--background-pack-selector-bullet, none);
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .PackSelectorItem-bullet {
    display: none;
  }
}
.PackSelectorItem-label {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
}
@media (min-width: 1280px) {
  .PackSelectorItem-label {
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
@media (max-width: 1919px) {
  .PackSelectorItem-label {
    flex-flow: column nowrap;
    align-items: flex-start;
  }
}
.PackSelectorItem-title {
  flex: 1 0 auto;
}
.PackSelectorItem-value {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 4px;
}
.PackSelectorItem:hover, .PackSelectorItem.is-active {
  font-weight: var(--font-weight-pack-selector-active, normal);
  color: var(--color-pack-selector-text-active, #FFFFFF);
  background: var(--background-pack-selector-active, #B30000);
}
.PackSelectorItem:hover:after, .PackSelectorItem.is-active:after {
  border-color: var(--color-pack-selector-border-active, #B30000);
}
.PackSelectorItem:hover .PackSelectorItem-bullet, .PackSelectorItem.is-active .PackSelectorItem-bullet {
  border-color: var(--color-pack-selector-bullet-border-active, #FFFFFF);
}
.PackSelectorItem.is-active .PackSelectorItem-bullet {
  background: var(--background-pack-selector-bullet-active, #FFFFFF);
}

.Parallax {
  position: relative;
  overflow: hidden;
}
.Parallax--full {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.ParallaxLayer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.PlatformOption {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: stretch;
  background-color: #000000;
  --platform-color: #565656;
  --platform-color-text: #FFFFFF;
  cursor: pointer;
}
.PlatformOption--psn {
  --platform-color: #184092;
}
.PlatformOption--xbox {
  --platform-color: #2D611F;
}
.PlatformOption--nsw {
  --platform-color: #A92319;
}
.PlatformOption--ios {
  --platform-color: #000000;
}
.PlatformOption--android {
  --platform-color: #2FA94E;
}
.PlatformOption--steam {
  --platform-color: #0A1935;
}
.PlatformOption--steam .PlatformOption-icon {
  background-color: #144679 !important;
}
.PlatformOption-icon {
  background-color: var(--platform-color);
  padding: 8px;
}
.PlatformOption-iconImage {
  display: inline-block;
  width: 60px;
  height: 48px;
  object-fit: contain;
  vertical-align: middle;
}
.PlatformOption-label {
  position: relative;
  display: grid;
  align-items: center;
}
.PlatformOption-labelOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--platform-color);
  opacity: 0.6;
  overflow: hidden;
}
.PlatformOption-labelOverlay:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 120%;
  background-color: rgba(0, 0, 0, 0.5);
  transform: skew(-10deg) translateX(-110%);
  transition: transform 350ms;
}
.PlatformOption-text {
  position: relative;
  padding: 0 8px;
  text-align: left;
  z-index: 1;
  font-family: var(--font-family-body, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 1.1111rem;
  line-height: 1.6;
  text-decoration: none;
  color: var(--platform-color-text);
}
.PlatformOption-text span {
  background-color: #DCC277;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 10px;
  position: relative;
  top: -3px;
  color: #000000;
  font-weight: bold;
  left: 5px;
}
.PlatformOption:hover .PlatformOption-labelOverlay:after {
  transform: skew(-10deg) translateX(10%);
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.clearfix {
  display: inline-block;
}

* html .clearfix {
  height: 1%;
}

.clearfix {
  display: block;
}

.PriceTag {
  position: relative;
  display: inline-grid;
  grid-template-columns: auto 1px auto;
  align-items: center;
  gap: 16px;
  padding: 16px;
  background: var(--color-button-background, #B30000);
  border: solid 1px var(--color-button-border, transparent);
  border-radius: 4px;
  font-family: var(--font-family-button, "Roboto", Arial, Helvetica, sans-serif);
  line-height: 1;
  color: var(--color-button-text, #FFFFFF);
  transition: 250ms;
  cursor: pointer;
}
.PriceTag:not(.is-disabled):hover, .PriceTag:not(.is-disabled):active, .PriceTag:not(.is-disabled):focus, .PriceTag:not(.is-purchased):hover, .PriceTag:not(.is-purchased):active, .PriceTag:not(.is-purchased):focus {
  filter: brightness(1.3);
}
.PriceTag-price {
  font-weight: 700;
  display: flex;
  align-items: center;
}
.PriceTag-plat {
  display: none;
  margin-right: 4px;
}
.PriceTag-sale {
  text-decoration: line-through;
}
.PriceTag-divider {
  width: 1px;
  background-color: var(--color-button-text, #FFFFFF);
  margin: -4px 0;
  height: calc(100% + 8px);
}
.PriceTag-label, .PriceTag-purchased, .PriceTag-disabled {
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}
.PriceTag-purchased, .PriceTag-disabled {
  display: none;
}
.PriceTag.is-disabled, .PriceTag.is-purchased {
  grid-template-columns: auto;
  background-color: #C8C8C8 !important;
  color: #000000;
  padding: 16px 40px;
  cursor: unset;
}
.PriceTag.is-disabled .PriceTag-content,
.PriceTag.is-disabled .PriceTag-divider, .PriceTag.is-purchased .PriceTag-content,
.PriceTag.is-purchased .PriceTag-divider {
  display: none;
}
.PriceTag.is-disabled .PriceTag-label, .PriceTag.is-purchased .PriceTag-label {
  display: none;
}
.PriceTag.is-disabled .PriceTag-disabled {
  display: block;
}
.PriceTag.is-purchased .PriceTag-purchased {
  display: block;
}
.PriceTag--large .PriceTag-price {
  font-size: 28px;
}
.PriceTag--large .PriceTag-label {
  font-size: 28px;
}
.PriceTag.plat-currency .PriceTag-content {
  position: relative;
}
.PriceTag.plat-currency .PriceTag-plat {
  display: inline-block;
}
.PriceTag.plat-currency.PriceTag--large .PriceTag-plat {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.Search-form {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 16px;
  background-color: #F6F6F6;
  padding: 32px;
}
.Search-text[type=text] {
  margin: 0;
  border: 0;
  border-bottom: solid 1px #565656;
  border-radius: 0;
  background: transparent;
}
.Search-text[type=text]::placeholder {
  color: #4F4F4F;
}
.Search-submit {
  position: relative;
  background-color: #1C4D6D;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.Search-submitIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  filter: invert(1);
}

.Slideshow {
  position: relative;
}
.Slideshow--background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.SlideshowSlide {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 1s;
  background: center/cover no-repeat;
}
.SlideshowSlide.is-active {
  opacity: 1;
}

.Tile {
  position: relative;
}
.Tile-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.Tile--default .Tile-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}
.Tile--captioned {
  display: grid;
  grid-template-rows: auto 1fr;
}
.Tile--captioned .Tile-text {
  text-align: center;
}

.FormFile {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background-color: #FFFFFF;
  border: dashed 1px #000000;
  padding: 32px 64px;
  text-align: center;
  cursor: pointer;
}
.FormFile-input {
  display: none;
}
.FormFile-icon {
  width: 32px;
  height: 32px;
  opacity: 0.65;
}
.FormFile-title {
  font-weight: bold;
  color: #565656;
}
.FormFile-button {
  background-color: #F6F6F6;
  border: solid 1px #C8C8C8;
  color: #000000;
  font-size: 16px;
  margin: 8px 0;
  padding: 2px 32px;
  transition: all 250ms;
}
.FormFile-subtext {
  color: #000000;
  font-size: 0.875rem;
}
.FormFile-error {
  color: #940000;
  font-size: 16px;
}
.FormFile:hover .FormFile-button {
  background-color: #C8C8C8;
  color: #FFFFFF;
}

.FormInput {
  position: relative;
}
.FormInput[type=text], .FormInput[type=password] {
  font-family: var(--font-family-form, Roboto, Arial, Helvetica, sans-serif);
  font-size: var(--font-size-form, 16px);
  padding: 16px;
  background-color: var(--form-background-color, #F6F6F6);
  color: var(--form-text-color, #000000);
  border: 0;
  border-bottom: solid 1px var(--form-border-color, #C8C8C8);
}
.FormInput[type=text]:focus, .FormInput[type=password]:focus {
  outline: none;
  border-color: var(--form-border-color-active, #000000);
}
.FormInput[type=text].is-error, .FormInput[type=password].is-error {
  border-color: var(--form-border-color-error, #940000);
}
.FormInput[type=text]::placeholder, .FormInput[type=password]::placeholder {
  color: var(--form-placeholder-color, #4F4F4F);
}
.FormInput[type=text].is-full, .FormInput[type=password].is-full {
  width: 100%;
}
.FormInput[type=checkbox], .FormInput[type=radio] {
  display: none;
}
.FormInput[type=checkbox]:checked + .FormInputCheckbox, .FormInput[type=checkbox]:checked + .FormInputRadio, .FormInput[type=radio]:checked + .FormInputCheckbox, .FormInput[type=radio]:checked + .FormInputRadio {
  background-color: #000000;
}
.FormInput[type=checkbox]:checked + .FormInputCheckbox:after, .FormInput[type=checkbox]:checked + .FormInputRadio:after, .FormInput[type=radio]:checked + .FormInputCheckbox:after, .FormInput[type=radio]:checked + .FormInputRadio:after {
  display: block;
}

.FormInputLabel {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 8px;
  font-family: var(--font-family-form, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 0.875rem;
  line-height: 1;
}

.FormInputCheckbox,
.FormInputRadio {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: var(--form-background-color, #F6F6F6);
  color: var(--form-text-color, #000000);
  border: solid 1px var(--form-border-color, #C8C8C8);
  border-radius: 4px;
}
.FormInputCheckbox:focus,
.FormInputRadio:focus {
  outline: none;
  border-color: var(--form-border-color-active, #000000);
}
.FormInputCheckbox.is-error,
.FormInputRadio.is-error {
  border-color: var(--form-border-color-error, #940000);
}
.FormInputCheckbox:after,
.FormInputRadio:after {
  content: "";
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.FormInputCheckbox:after {
  width: 16px;
  height: 16px;
  background-image: url(/images/icons/material/check.svg);
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center;
  filter: invert(1);
}

.FormInputRadio {
  border-radius: 50%;
}
.FormInputRadio:after {
  width: 6px;
  height: 6px;
  background-color: #FFFFFF;
  border-radius: 50%;
}

.FormInputWrapper {
  position: relative;
  display: inline;
}
.FormInputWrapper-content {
  position: relative;
  display: inline;
}
.FormInputWrapper-error {
  color: #940000;
  font-size: 16px;
}
.FormInputWrapper.is-icon .FormInput {
  padding-left: 44px;
}
.FormInputWrapper.is-icon .FormInputWrapper-content:before {
  content: "";
  position: absolute;
  left: 16px;
  top: 50%;
  height: 20px;
  width: 20px;
  transform: translateY(-50%);
  pointer-events: none;
  background-image: var(--input-icon);
  background-size: 20px 20px;
  opacity: 70%;
  z-index: 1;
}

.customRadio {
  display: block;
  padding: 16px 24px;
  border-radius: 8px;
  background-color: rgb(50, 50, 50);
  margin-bottom: 16px;
}
.customRadio .FormInputLabel-text {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: "Roboto";
  font-size: 18px;
  line-height: 28.8px;
  font-weight: 400;
}
.customRadio .FormInputLabel-text .PriceCrossed {
  text-decoration: line-through;
  opacity: 0.8;
  font-weight: 400;
}
.customRadio .FormInputLabel-text .TextSaleTag {
  text-wrap: nowrap;
  padding: 4px 8px;
  font-size: 0.75rem;
  line-height: 0;
  border-radius: 4px;
  background: linear-gradient(90deg, #b30000 0%, rgb(163, 0, 90) 100%);
}
.customRadio .FormInputRadio {
  background-color: var(--color-selector-background, rgb(50, 50, 50));
  border: 1px solid var(--color-selector-border, rgb(151, 151, 151));
}
.customRadio .FormInputRadio::after {
  background-color: var(--color-selector-bg-after, rgb(187, 37, 48));
}
@media (max-width: 767px) {
  .customRadio .FormInputRadio {
    margin-top: 5px;
  }
}
.customRadio .FormInput[type=radio]:checked + .FormInputRadio {
  border: 1px solid var(--color-selector-border-checked, rgb(187, 37, 48));
  background-color: var(--color-selector-bg-checked, rgb(187, 37, 48));
}
@media (max-width: 767px) {
  .customRadio .FormInput[type=radio]:checked + .FormInputRadio {
    margin-top: 5px;
  }
}

.customRadio:has(input[type=radio]:checked) {
  border: 1px solid var(--color-selector-custom-border-checked, rgb(187, 37, 48));
  background-color: var(--color-selector-custom-bg-checked, rgb(64, 64, 64));
}

.FormSelect {
  -moz-appearance: none;
  -webkit-appearance: none;
  font-family: var(--font-family-form, Roboto, Arial, Helvetica, sans-serif);
  font-size: var(--font-size-form, 16px);
  padding: 16px 44px 16px 16px;
  background-color: var(--form-background-color, #F6F6F6);
  color: var(--form-text-color, #000000);
  border: solid 1px var(--form-border-color, #C8C8C8);
  border-radius: 4px;
}
.FormSelect:focus {
  outline: none;
  border-color: var(--form-border-color-active, #000000);
}
.FormSelect.is-error {
  border-color: var(--form-border-color-error, #940000);
}
.FormSelect::placeholder {
  color: var(--form-placeholder-color, #4F4F4F);
}
.FormSelect.is-full {
  width: 100%;
}
.FormSelect option {
  background-color: #FFFFFF;
  color: #000000;
}
.FormSelect option:hover {
  background-color: #F6F6F6;
}
.FormSelect::-ms-expand {
  display: none;
}

.FormSelectWrapper {
  position: relative;
  display: inline;
}
.FormSelectWrapper-content {
  position: relative;
  display: inline;
}
.FormSelectWrapper-error {
  color: #940000;
  font-size: 16px;
}
.FormSelectWrapper-content:after {
  content: "";
  position: absolute;
  right: 4px;
  top: 50%;
  height: 20px;
  width: 20px;
  transform: translateY(-50%);
  pointer-events: none;
  background-image: url(/images/icons/material/dropdown.svg);
  background-size: 20px 20px;
  opacity: 70%;
  z-index: 1;
}
.FormSelectWrapper.is-icon .FormSelect {
  padding-left: 44px;
}
.FormSelectWrapper.is-icon .FormSelectWrapper-content:before {
  content: "";
  position: absolute;
  left: 16px;
  top: 50%;
  height: 20px;
  width: 20px;
  transform: translateY(-50%);
  pointer-events: none;
  background-image: var(--input-icon);
  background-size: 20px 20px;
  opacity: 70%;
  z-index: 1;
}

.FormText {
  font-family: var(--font-family-form, Roboto, Arial, Helvetica, sans-serif);
  font-size: var(--font-size-form, 16px);
  padding: 16px;
  background-color: var(--form-background-color, #F6F6F6);
  color: var(--form-text-color, #000000);
  border: solid 1px var(--form-border-color, #C8C8C8);
  border-radius: 4px;
}
.FormText:focus {
  outline: none;
  border-color: var(--form-border-color-active, #000000);
}
.FormText.is-error {
  border-color: var(--form-border-color-error, #940000);
}
.FormText::placeholder {
  color: var(--form-placeholder-color, #4F4F4F);
}
.FormText.is-full {
  width: 100%;
}

.FormTextWrapper {
  position: relative;
  display: inline;
}
.FormTextWrapper-content {
  position: relative;
  display: inline;
}
.FormTextWrapper-error {
  color: #940000;
  font-size: 16px;
}

.HeaderNavigationContainer {
  position: relative;
  height: 0;
  box-sizing: border-box;
  z-index: 200;
}
.HeaderNavigationBar {
  --color-navigation-text: #000000;
  --color-navigation-text-hover: #000000;
  --color-navigation-text-dropdown: #136387;
  --color-navigation-background: rgba(255, 255, 255, 0.92);
  --color-navigation-background-dropdown: #FEFEFE;
  --color-navigation-background-hover: #FFFFFF;
  --color-navigation-border: rgba(255, 255, 255, 0.92);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  color: var(--color-navigation-text);
  z-index: 200;
  max-width: 100%;
}
.HeaderNavigationBar:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-navigation-background);
  backdrop-filter: blur(10px);
}
.HeaderNavigationBar:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  height: 1px;
  background: var(--color-navigation-border);
  pointer-events: none;
}
.HeaderNavigationBar-grid {
  position: relative;
  height: 66px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 16px;
  padding: 0 16px;
}
@media (max-width: 449px) {
  .HeaderNavigationBar-grid {
    gap: 0 8px;
    padding: 0 8px;
  }
}
.HeaderNavigationBar-logoContainer {
  height: 100%;
}
.HeaderNavigationBar-logoLink {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 100%;
  padding: 0 16px;
}
@media (max-width: 449px) {
  .HeaderNavigationBar-logoLink {
    padding: 0;
  }
}
.HeaderNavigationBar-logo {
  height: 29px;
}
.HeaderNavigationBar-mobileLogo {
  display: none;
  height: 42px;
}
.HeaderNavigationBar-desktopMenuContainer {
  position: relative;
  text-align: right;
  height: 100%;
  overflow-x: hidden;
}
.HeaderNavigationBar-controlsContainer {
  text-align: right;
  height: 100%;
  min-width: 232px;
}
.HeaderNavigationBar-mobileMenuToggle {
  display: none;
  text-align: right;
}
.HeaderNavigationBar-mobileMenuToggleInner {
  display: inline-block;
  padding: 11px;
  cursor: pointer;
  height: 44px;
  width: 44px;
  border: solid 1px var(--color-navigation-dropdown-toggle-border, #C8C8C8);
  border-radius: 4px;
}
.HeaderNavigationBar-mobileMenuOverlay {
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 78px;
  right: 0;
  left: 0;
  bottom: 0;
}
.HeaderNavigationBar-mobileMenuContainer {
  display: none;
  position: fixed;
  top: 78px;
  right: 0;
  bottom: 0;
  min-width: 380px;
  overflow-y: auto;
  z-index: 200;
  background-color: var(--color-navigation-background);
  backdrop-filter: blur(10px);
}
.HeaderNavigationBar-mobileButtonContainer {
  display: none;
}
.HeaderNavigationBar.is-desktop .HeaderNavigationBar-desktopMenuContainer {
  overflow-x: unset;
}
.HeaderNavigationBar.is-collapsed .HeaderNavigationBar-grid {
  height: 78px;
  grid-template-columns: auto 1fr auto;
}
.HeaderNavigationBar.is-collapsed .HeaderNavigationBar-controlsContainer {
  overflow-x: hidden;
}
.HeaderNavigationBar.is-collapsed .HeaderNavigationBar-desktopMenuContainer {
  display: none;
}
.HeaderNavigationBar.is-collapsed .HeaderNavigationBar-mobileMenuToggle {
  display: block;
}
.HeaderNavigationBar.is-collapsed .HeaderUserMenu {
  position: fixed;
  top: 78px;
  bottom: 0;
  right: 0;
  min-width: 360px;
}
.HeaderNavigationBar.is-collapsed .HeaderUserMenu-content {
  padding: 0;
}
.HeaderNavigationBar.is-collapsed .HeaderUserMenu-row, .HeaderNavigationBar.is-collapsed .HeaderUserMenu-link {
  padding: 16px 32px;
}
.HeaderNavigationBar.is-collapsed .HeaderUserMenu-crossSave {
  display: none;
}
.HeaderNavigationBar.is-collapsed .HeaderUserMenu-crossSave.is-enabled {
  display: block;
}
.HeaderNavigationBar.is-collapsed.is-mobile {
  --color-navigation-background: #FFFFFF;
  --color-navigation-background-dropdown: #F3F3F3;
}
.HeaderNavigationBar.is-collapsed.is-mobile .HeaderNavigationBar-grid {
  grid-template-columns: auto 1fr;
  height: 58px;
}
.HeaderNavigationBar.is-collapsed.is-mobile .HeaderNavigationBar-mobileLogo {
  height: 21px;
}
.HeaderNavigationBar.is-collapsed.is-mobile .HeaderNavigationBar-controlsContainer {
  display: none;
}
.HeaderNavigationBar.is-collapsed.is-mobile .HeaderNavigationBar-mobileMenuOverlay {
  top: 58px;
}
.HeaderNavigationBar.is-collapsed.is-mobile .HeaderNavigationBar-mobileMenuContainer {
  left: 0;
  top: 58px;
  min-width: auto;
}
.HeaderNavigationBar.is-collapsed.is-mobile .HeaderNavigationBar-mobileButtonContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  padding: 0 16px;
  gap: 16px;
  margin-top: 16px;
}
.HeaderNavigationBar.is-collapsed.is-mobile .HeaderUserMenu {
  position: fixed;
  top: 58px;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  min-width: auto;
}
.HeaderNavigationBar.is-collapsed.is-open .HeaderNavigationBar-mobileMenuOverlay {
  display: block;
}
.HeaderNavigationBar.is-collapsed.is-open .HeaderNavigationBar-mobileMenuContainer {
  display: block;
}
.HeaderNavigationBar.is-collapsed.is-user-open .HeaderNavigationBar-mobileMenuOverlay {
  display: block;
}
.HeaderNavigationBar.is-user-open .HeaderUserMenu {
  display: grid;
}
.HeaderNavigationBar--dark {
  --color-navigation-text: #FFFFFF;
  --color-navigation-text-hover: #FFFFFF;
  --color-navigation-text-dropdown: #65C4ED;
  --color-navigation-background: rgba(24, 21, 28, 0.92);
  --color-navigation-background-hover: #000000;
}

.HeaderMenuItem-live {
  display: inline-block;
  padding: 8px;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
  background-color: #DDC57D;
  color: #000000;
}

.HeaderDesktopMenu {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 8px;
  height: 100%;
  text-align: left;
  white-space: nowrap;
}
.HeaderDesktopMenu .HeaderMenuItem {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  white-space: nowrap;
}
.HeaderDesktopMenu .HeaderMenuItem-label {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border: solid 1px transparent;
  border-radius: 4px;
  color: var(--color-navigation-text);
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}
.HeaderDesktopMenu .HeaderMenuItem-label:hover, .HeaderDesktopMenu .HeaderMenuItem-label:active {
  text-decoration: none;
}
.HeaderDesktopMenu .HeaderMenuItem-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  pointer-events: none;
}
.HeaderDesktopMenu .HeaderMenuItem-live {
  margin-left: 8px;
  margin-top: -8px;
  margin-bottom: -8px;
}
.HeaderDesktopMenu .HeaderMenuItem-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  list-style: none;
  padding-top: 26px;
  padding-bottom: 18px;
  line-height: 1.2;
  cursor: pointer;
  z-index: 200;
}
.HeaderDesktopMenu .HeaderMenuItem-dropdown:before {
  content: "";
  position: absolute;
  top: 8px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-navigation-background-dropdown);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}
.HeaderDesktopMenu .HeaderMenuItem-subheading {
  position: relative;
  color: #000000;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 8px 16px;
}
.HeaderDesktopMenu .HeaderMenuItem-option {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 4px;
  color: var(--color-navigation-text-dropdown);
  text-decoration: underline transparent;
  font-size: 16px;
  font-weight: 400;
  padding: 8px 16px;
  transition: 250ms;
}
.HeaderDesktopMenu .HeaderMenuItem-option:hover {
  text-decoration-color: var(--color-navigation-text-dropdown);
}
.HeaderDesktopMenu .HeaderMenuItem-optionLink {
  color: var(--color-navigation-text-dropdown);
  padding: 8px;
  filter: brightness(0) saturate(100%);
}
.HeaderDesktopMenu .HeaderMenuItem--dropdown .HeaderMenuItem-label, .HeaderDesktopMenu .HeaderMenuItem--external .HeaderMenuItem-label {
  padding-right: 24px;
}
.HeaderDesktopMenu .HeaderMenuItem.is-highlighted .HeaderMenuItem-label {
  color: #5143A8;
}
.HeaderDesktopMenu .HeaderMenuItem.is-highlighted .HeaderMenuItem-label:hover {
  color: #5143A8;
}
.HeaderDesktopMenu .HeaderMenuItem.is-current .HeaderMenuItem-label, .HeaderDesktopMenu .HeaderMenuItem.is-open .HeaderMenuItem-label, .HeaderDesktopMenu .HeaderMenuItem:hover .HeaderMenuItem-label {
  background-color: var(--color-navigation-background-hover);
  color: var(--color-navigation-text-hover);
  border-color: var(--color-navigation-dropdown-toggle-border, #C8C8C8);
}
.HeaderDesktopMenu .HeaderMenuItem.is-open .HeaderMenuItem-dropdown {
  display: block;
}
.HeaderDesktopMenu .HeaderMenuItem:not(.is-closed):hover .HeaderMenuItem-dropdown {
  display: block;
}
.HeaderDesktopMenu-bar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 4px;
  background-color: #B30000;
  pointer-events: none;
}

.HeaderNewLabel {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 8px;
}
.HeaderNewLabel-tag {
  display: inline-block;
  padding: 8px;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
  background-color: #DDC57D;
  color: #000000;
}

.HeaderMobileMenu {
  position: relative;
}
.HeaderMobileMenu .HeaderMenuItem {
  position: relative;
  display: block;
  height: 100%;
  text-align: left;
  white-space: nowrap;
}
.HeaderMobileMenu .HeaderMenuItem-label {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 16px;
  padding: 8px 16px;
  color: var(--color-navigation-text);
  font-weight: bold;
  cursor: pointer;
}
.HeaderMobileMenu .HeaderMenuItem-label.is-inline {
  display: flex;
  gap: 0;
}
.HeaderMobileMenu .HeaderMenuItem-icon {
  padding: 12px;
  width: 44px;
  height: 44px;
  margin: -12px 0;
}
.HeaderMobileMenu .HeaderMenuItem-live {
  margin-left: 16px;
}
.HeaderMobileMenu .HeaderMenuItem-dropdown {
  display: none;
  background-color: var(--color-navigation-background-dropdown);
  list-style: none;
  padding-bottom: 8px;
  cursor: pointer;
}
.HeaderMobileMenu .HeaderMenuItem-dropdown:hover {
  color: #565656;
}
.HeaderMobileMenu .HeaderMenuItem-dropdown:hover .HeaderMenuItem-linkIcon {
  filter: var(--filter-navigation-icon-dropdown, invert(36%) sepia(0%) saturate(0%) hue-rotate(327deg) brightness(91%) contrast(87%));
}
.HeaderMobileMenu .HeaderMenuItem-subheading {
  position: relative;
  color: #000000;
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 16px 32px 8px;
}
.HeaderMobileMenu .HeaderMenuItem-option {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  font-weight: 400;
  padding: 8px 32px;
  --color-link: inherit;
  --color-link-hover: #000000;
}
.HeaderMobileMenu .HeaderMenuItem-option:hover {
  background-color: #FEFEFE;
}
.HeaderMobileMenu .HeaderMenuItem-option:hover .HeaderMenuItem-linkIcon {
  filter: brightness(0) saturate(100%);
}
.HeaderMobileMenu .HeaderMenuItem.is-highlighted .HeaderMenuItem-label {
  color: #5143A8;
}
.HeaderMobileMenu .HeaderMenuItem.is-highlighted .HeaderMenuItem-label:hover {
  color: #5143A8;
}
.HeaderMobileMenu .HeaderMenuItem.is-open .HeaderMenuItem-dropdown {
  display: block;
}
.HeaderMobileMenu .HeaderMenuItem.is-open .HeaderMenuItem-icon {
  transform: rotate(180deg);
}
.HeaderMobileMenu .HeaderMenuItem .HeaderMenuItem-label .event-label {
  display: flex;
  flex-direction: row;
}
.HeaderMobileMenu .HeaderMenuItem .HeaderMenuItem-label .event-label .new-label, .HeaderMobileMenu .HeaderMenuItem .HeaderMenuItem-option .new-label {
  margin-right: 10px;
  padding: 2px 5px;
  background-color: #B30000 !important;
  border-radius: 2px;
  color: white;
  text-transform: uppercase;
}
.HeaderMobileMenu .HeaderMenuItem .HeaderMenuItem-option .new-label {
  margin-right: 4px;
}

.HeaderNavigationControls {
  display: inline-grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 16px;
  white-space: nowrap;
  height: 100%;
}

.HeaderNavigationLoggedIn {
  display: none;
}
.HeaderNavigationLoggedOut {
  display: none;
}

body.logged-in .HeaderNavigationLoggedIn {
  display: inline;
}
body.logged-in .is-collapsed .HeaderNavigationBar-logo {
  display: none;
}
body.logged-in .is-collapsed .HeaderNavigationBar-mobileLogo {
  display: block;
}
body.loaded-in .HeaderUserProfile {
  filter: opacity(1);
}
body.logged-out .HeaderNavigationLoggedOut {
  display: inline;
}

.HeaderUserProfile {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  text-align: left;
  filter: opacity(0);
  transition: filter 250ms;
}
.HeaderUserProfile-container {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 0 8px;
  cursor: pointer;
}
.HeaderUserProfile-avatar {
  position: relative;
  height: 42px;
  width: 42px;
  grid-row: 1/span 2;
  background: linear-gradient(to bottom left, #fff, #979797 50%, #bababa 100%);
}
.HeaderUserProfile-name {
  position: relative;
  font-weight: 700;
  line-height: 1;
  padding-right: 32px;
}
.HeaderUserProfile-nameText {
  display: inline-block;
  width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media (max-width: 767px) {
  .HeaderUserProfile-nameText {
    width: 125px;
  }
}
.HeaderUserProfile-information {
  line-height: 1;
}
.HeaderUserProfile-legendary {
  display: none;
}
.HeaderUserProfile-platinum {
  display: inline;
}
.HeaderUserProfile-platinumDivider {
  position: relative;
  padding-left: 16px;
}
.HeaderUserProfile-platinumDivider:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 8px;
  width: 1px;
  height: 0.9rem;
  background-color: #000000;
  transform: translateY(-50%);
}
.HeaderUserProfile-platinumIcon {
  height: 14px;
  width: 14px;
  position: relative;
  bottom: -1px;
}
.HeaderUserProfile-dropdownIcon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.HeaderUserProfile.is-notification-active .HeaderUserProfile-container:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #B30000;
  transform: translate(-50%, -50%);
}
.HeaderUserProfile.is-legendary .HeaderUserProfile-mastery {
  display: none;
}
.HeaderUserProfile.is-legendary .HeaderUserProfile-legendary {
  display: unset;
}

.HeaderUserMenu {
  --color-link: var(--color-navigation-text-dropdown);
  --color-link-hover: var(--color-navigation-text-dropdown);
  display: none;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
  position: absolute;
  right: 16px;
  top: 100%;
  min-width: 232px;
}
.HeaderUserMenu:before {
  content: "";
  position: absolute;
  top: 8px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-navigation-background-dropdown);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}
.HeaderUserMenu-content {
  position: relative;
  padding: 8px 0;
}
.HeaderUserMenu-row {
  position: relative;
  padding: 8px 16px;
}
.HeaderUserMenu-row.is-hidden {
  display: none;
}
.HeaderUserMenu-row.is-active {
  display: block;
}
.HeaderUserMenu-row.is-highlighted:before {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  bottom: 8px;
  width: 4px;
  background-color: #B30000;
}
.HeaderUserMenu-row.is-unlinked .HeaderUserMenu-unlinked {
  display: unset;
}
.HeaderUserMenu-row.is-linked .HeaderUserMenu-linked {
  display: unset;
}
.HeaderUserMenu-row.is-claimable .HeaderUserMenu-rewards {
  display: unset;
}
.HeaderUserMenu-row.is-redeemable .HeaderUserMenu-redeem {
  display: unset;
}
.HeaderUserMenu-rowTitle {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-weight: 700;
}
.HeaderUserMenu-linked, .HeaderUserMenu-unlinked, .HeaderUserMenu-rewards, .HeaderUserMenu-redeem, .HeaderUserMenu-coupon, .HeaderUserMenu-couponDiscount, .HeaderUserMenu-couponMultiplier {
  display: none;
}
.HeaderUserMenu-crossSaveIcon {
  margin-right: 8px;
  filter: invert(7%) sepia(84%) saturate(7135%) hue-rotate(4deg) brightness(104%) contrast(110%);
}
.HeaderUserMenu-twitchIcon {
  margin-right: 8px;
  filter: invert(24%) sepia(49%) saturate(1527%) hue-rotate(226deg) brightness(92%) contrast(91%);
}
.HeaderUserMenu-primeIcon {
  margin-right: 8px;
  filter: invert(24%) sepia(49%) saturate(1527%) hue-rotate(226deg) brightness(92%) contrast(91%);
}
.HeaderUserMenu-claimLink {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.HeaderUserMenu-claimIcon {
  margin-left: 4px;
}
.HeaderUserMenu-coupon, .HeaderUserMenu-couponDiscount, .HeaderUserMenu-couponMultiplier {
  display: none;
}
.HeaderUserMenu-coupon.is-active, .HeaderUserMenu-couponDiscount.is-active, .HeaderUserMenu-couponMultiplier.is-active {
  display: block;
}
.HeaderUserMenu-redeemText {
  white-space: normal;
  max-width: 250px;
}
.HeaderUserMenu-platinum {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: #565656;
}
.HeaderUserMenu-platinumIcon {
  margin-right: 4px;
}
.HeaderUserMenu-platinumDivider {
  margin: 0 4px;
}
.HeaderUserMenu-platinumLink {
  text-decoration: underline;
}
.HeaderUserMenu-divider {
  position: relative;
  background-color: #C8C8C8;
  height: 1px;
}
.HeaderUserMenu-links {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto 1fr;
  white-space: nowrap;
  padding: 8px 0;
}
.HeaderUserMenu-link {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
}
.HeaderUserMenu-link > .Icon {
  margin-left: 8px;
}
.HeaderUserMenu-linkLast {
  align-self: end;
}

.HeaderTakeover {
  position: relative;
  display: none;
  height: 0;
  overflow: hidden;
  background-color: #07090B;
  transition: height 1s ease-in-out;
  background-image: url("../images/promo/whispers/takeover/runes-on-walls.jpg");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
}
.HeaderTakeover-wrapper {
  position: relative;
  max-width: 1064px;
  margin: 0 auto;
  overflow-x: auto;
}
.HeaderTakeover-grid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, minmax(320px, 1fr));
  gap: 16px;
  padding: 32px;
}
.HeaderTakeover-link {
  color: #FFFFFF;
}
.HeaderTakeover-closer {
  position: absolute;
  top: 0;
  right: 8px;
  background-color: #7E5F4A;
  padding: 8px;
  border-radius: 0 0 4px 4px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
@media (min-width: 1920px) {
  .HeaderTakeover-closer {
    right: auto;
    left: calc(50% + 744px);
  }
}
.HeaderTakeover-closerIcon {
  height: 24px;
  width: 24px;
  filter: invert(1);
}
.HeaderTakeover.is-primed {
  display: block;
}
.HeaderTakeover.is-active {
  height: 174px;
}
.HeaderTakeover .HeaderTakeoverCard {
  position: relative;
  border: solid 1px #936C52;
  display: grid;
  grid-template-columns: auto 1fr;
  min-width: 320px;
}
.HeaderTakeover .HeaderTakeoverCard-image {
  vertical-align: bottom;
  height: 110px;
  aspect-ratio: 4/3;
  object-fit: cover;
}
.HeaderTakeover .HeaderTakeoverCard-content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  color: #FFFFFF;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
  font-weight: bold;
  padding: 0 16px;
}
.HeaderTakeover .HeaderTakeoverCard-content strong {
  color: #F5E6A0;
}
.HeaderTakeover.WitwPromo .HeaderTakeover-wrapper {
  max-width: 1280px;
}
.HeaderTakeover.WitwPromo .HeaderTakeover-grid {
  padding: 0;
  grid-template-columns: auto auto minmax(320px, 1fr);
}
.HeaderTakeover.WitwPromo .HeaderTakeover-grid.short {
  grid-template-columns: repeat(2, auto);
}
@media (min-width: 1024px) {
  .HeaderTakeover.WitwPromo .HeaderTakeover-grid.short {
    display: none;
  }
}
@media (max-width: 600px) {
  .HeaderTakeover.WitwPromo .HeaderTakeover-grid.short {
    grid-template-columns: repeat(2, 50%);
  }
}
@media (max-width: 1023px) {
  .HeaderTakeover.WitwPromo .HeaderTakeover-grid.long {
    display: none;
  }
}
.HeaderTakeover.WitwPromo .HeaderTakeover-fullHeightImage {
  max-height: 174px;
  max-width: 100%;
  margin: 0;
}
@media (max-width: 1024px) {
  .HeaderTakeover.WitwPromo .HeaderTakeover-fullHeightImage {
    margin: auto;
  }
}
.HeaderTakeover.WitwPromo .HeaderTakeoverDescriptionText {
  color: white;
  padding: 32px 8px;
}
.HeaderTakeover.WitwPromo .HeaderTakeoverDescriptionText-title {
  font-weight: bold;
}
.HeaderTakeover.WitwPromo .HeaderTakeoverButton {
  margin-top: 8px;
  border: rgb(245, 230, 160) 1px solid;
  background: black;
  color: rgb(245, 230, 160);
}
.HeaderTakeover.WitwPromo .HeaderTakeoverCard {
  margin: 32px 0;
}

.FooterNavigationContainer {
  position: relative;
  background-color: #F6F6F6;
  z-index: 10;
  color: #000000;
}
.FooterNavigationContainer.is-inverted {
  color: #FFFFFF;
  background-color: #000000;
}
.FooterNavigationContainer.is-inverted .FooterPlatformIcons,
.FooterNavigationContainer.is-inverted .FooterNavigationLanguageDropdown .Icon {
  filter: invert(1);
}
.FooterNavigationContainer.is-inverted .FooterCopyrightText {
  color: #FFFFFF;
}
.FooterNavigationContainer.is-inverted .FooterNavigationLinkDivider {
  background-color: #FFFFFF;
}
.FooterNavigationContainer.is-inverted .FooterNavigationLanguageDropdown-dropdown {
  background-color: #18151C;
}
.FooterNavigationContainer.is-inverted .FooterNavigationLanguageDropdown-option:hover {
  background-color: #18151C;
}
.FooterNavigationContainer.is-inverted .FooterNavigationBar {
  color: #FFFFFF;
}
@media (min-width: 1280px) {
  .FooterNavigationContainer.is-inverted .FooterNavigationBar-inner {
    background-color: #18151C;
  }
}
.FooterNavigationContainer.is-inverted .FooterNavigationLink,
.FooterNavigationContainer.is-inverted .FooterRatingsContainer-content,
.FooterNavigationContainer.is-inverted .FooterNavigationLanguageDropdown-label,
.FooterNavigationContainer.is-inverted .FooterNavigationLanguageDropdown-optionLink {
  color: #FFFFFF;
}

.FooterNavigationBar {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: 16px 0;
  color: #000000;
  line-height: 1.6;
}
@media (min-width: 1920px) {
  .FooterNavigationBar {
    width: 1632px;
  }
}
.FooterNavigationBar-content {
  position: relative;
}
@media (max-width: 767px) {
  .FooterNavigationBar-content {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .FooterNavigationBar-content {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .FooterNavigationBar-content {
    padding-left: 80px;
    padding-right: 80px;
  }
}
@media (min-width: 1920px) {
  .FooterNavigationBar-content {
    padding-left: 144px;
    padding-right: 144px;
  }
}
.FooterNavigationBar-inner {
  position: relative;
}
@media (max-width: 767px) {
  .FooterNavigationBar-inner {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .FooterNavigationBar-inner {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .FooterNavigationBar-inner {
    padding-left: 80px;
    padding-right: 80px;
  }
}
@media (min-width: 1920px) {
  .FooterNavigationBar-inner {
    padding-left: 144px;
    padding-right: 144px;
  }
}
@media (min-width: 1280px) {
  .FooterNavigationBar-inner {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 16px;
    background-color: #F0F0F0;
  }
}
.FooterNavigationBar-linkContainer {
  overflow-x: hidden;
  text-align: center;
}
@media (max-width: 1279px) {
  .FooterNavigationBar-links {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    text-align: left;
    gap: 16px;
    margin: 16px 0;
  }
}
@media (max-width: 767px) {
  .FooterNavigationBar-links {
    grid-template-columns: 1fr;
  }
}
@media (min-width: 1280px) {
  .FooterNavigationBar-links {
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin: 16px 0;
  }
}
.FooterNavigationBar-dropdownContainer {
  text-align: center;
}
.FooterNavigationBar.is-expanded .FooterNavigationBar-content {
  grid-template-columns: auto 1fr auto;
  align-items: center;
}
.FooterNavigationBar.is-expanded .FooterNavigationBar-homeLink {
  margin-right: 32px;
}
.FooterNavigationBar.is-expanded .FooterNavigationBar-linkContainer {
  text-align: left;
}
.FooterNavigationBar.is-expanded .FooterNavigationBar-links {
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin: 0;
  white-space: nowrap;
}
.FooterNavigationBar.is-expanded .FooterNavigationBar-dropdownContainer {
  text-align: right;
}

.FooterNavigationLink {
  margin: 0 16px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  --color-link: #000000;
  text-decoration: none;
}
.FooterNavigationLink:hover, .FooterNavigationLink:active {
  text-decoration: none;
}
.FooterNavigationLink #ot-sdk-btn.ot-sdk-show-settings {
  border: none;
  padding: 0;
  font: inherit;
  color: unset;
  background-color: unset;
  cursor: pointer;
  white-space: nowrap;
  word-break: normal;
}
.FooterNavigationLink #ot-sdk-btn.ot-sdk-show-settings:hover {
  color: var(--color-button-text-hover);
  background-color: unset;
}

.FooterNavigationLinkDivider {
  margin: -4px 16px;
  height: 24px;
  width: 1px;
  background-color: #000000;
}
@media (max-width: 1279px) {
  .FooterNavigationLinkDivider {
    display: none;
  }
}

.FooterNavigationLanguageDropdown {
  position: relative;
  display: inline-block;
  text-align: left;
}
.FooterNavigationLanguageDropdown-overlay {
  display: inline-grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding: 8px;
  gap: 8px;
  background-color: transparent;
  border: solid 1px #C8C8C8;
  cursor: pointer;
}
.FooterNavigationLanguageDropdown-label {
  min-width: 100px;
  color: #000000;
  text-transform: uppercase;
  font-weight: 700;
}
.FooterNavigationLanguageDropdown-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #FFFFFF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  list-style: none;
  cursor: pointer;
  padding: 0;
  z-index: 200;
}
.FooterNavigationLanguageDropdown-option {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  text-transform: uppercase;
}
.FooterNavigationLanguageDropdown-option:hover {
  background-color: #F6F6F6;
}
.FooterNavigationLanguageDropdown-optionLink {
  display: block;
  --color-link: #000000;
  padding: 4px 16px;
}
.FooterNavigationLanguageDropdown.is-open .FooterNavigationLanguageDropdown-dropdown {
  display: block;
}

.FooterLowerContainer {
  line-height: 1.6;
}
.FooterLowerContainer-inner {
  padding-bottom: 128px;
}

.FooterSocialLinks {
  padding: 32px 0;
  text-align: center;
}
@media (min-width: 1920px) {
  .FooterSocialLinks {
    width: 1632px;
    margin-left: auto;
    margin-right: auto;
  }
}
.FooterSocialLinks-title {
  font-size: 1.5em;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
}
.FooterSocialLinks-divider {
  margin: 16px 0;
  height: 1px;
  background-image: linear-gradient(to right, transparent, #C8C8C8, transparent);
}

.FooterSocialLink {
  display: inline-block;
  padding: 10px;
  height: 40px;
  width: 40px;
  margin: 0 4px;
  background-color: #DDC57D;
  border-radius: 50%;
}
.FooterSocialLink[data-platform=vkontakte], .FooterSocialLink[data-platform=line] {
  display: none;
}

.lang-ru .FooterSocialLink[data-platform=vkontakte] {
  display: inline-block;
}
.lang-ru .FooterSocialLink[data-platform=facebook] {
  display: none;
}

.lang-ja .FooterSocialLink[data-platform=line] {
  display: inline-block;
}

.FooterRatingsContainer {
  text-align: center;
}
.FooterRatingsContainer-content {
  position: relative;
  display: inline-block;
  text-align: left;
  color: #000000;
}

.FooterRatingBoardCard {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
  align-items: center;
}
.FooterRatingBoardCard-image {
  height: 80px;
  aspect-ratio: 34/50;
  object-fit: contain;
}
.FooterRatingBoardCard-divider {
  height: 1px;
  background-color: #C8C8C8;
  margin: 8px 0;
}
.FooterRatingBoardCard ul {
  list-style: disc;
  padding-inline-start: 16px;
}
.FooterRatingBoardCard--noText {
  grid-template-columns: auto;
}

.FooterLogo {
  text-align: center;
  margin: 32px 0;
}
.FooterLogo-image {
  display: inline-block;
  width: 364px;
}

.FooterCopyrightText {
  font-size: 0.875rem;
  text-align: center;
  margin-bottom: 32px;
}

.FooterPlatformIcons {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-items: center;
  justify-content: center;
  gap: 8px 16px;
  width: 90%;
  margin: 0 auto 32px;
}
.FooterPlatformIcons-steam {
  height: 20px;
}
.FooterPlatformIcons-epic {
  height: 32px;
}
.FooterPlatformIcons-switch {
  height: 34px;
}
.FooterPlatformIcons-psn {
  height: 36px;
}
.FooterPlatformIcons-xbox {
  height: 40px;
}
.FooterPlatformIcons-ios {
  height: 40px;
}
.FooterPlatformIcons-xboxone {
  height: 40px;
  filter: invert(1);
}

.AuthModal {
  position: fixed;
  display: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 500;
  pointer-events: none;
}
.AuthModal.is-active {
  display: block;
  pointer-events: all;
}
.AuthModal-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
}
.AuthModal-closerRow {
  position: relative;
  margin: 32px auto 0;
  width: 80%;
  max-width: 1440px;
  text-align: right;
}
@media (max-width: 767px) {
  .AuthModal-closerRow {
    margin: 0;
    width: 100%;
    text-align: left;
  }
}
.AuthModal-closer {
  position: relative;
  text-align: left;
  cursor: pointer;
  margin-right: -24px;
  z-index: 10;
  display: inline-grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  gap: 0;
  padding: 16px;
}
.AuthModal-closer .Icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  filter: invert(1);
}
.AuthModal-closer span {
  color: #FFFFFF;
}
.AuthModal-logo {
  margin-top: 32px;
  text-align: center;
}
.AuthModal-logoImage {
  display: inline-block;
  width: 80%;
  max-width: 462px;
}
.AuthModal-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
}
.AuthModal a {
  color: #FFFFFF;
}
.AuthModal a:hover {
  color: #B30000;
}

.AuthModalPage {
  display: none;
  position: relative;
  margin: 0 auto;
  width: 80%;
  max-width: 1440px;
}
.AuthModalPage.is-active {
  display: block;
}
.AuthModalLogo {
  text-align: center;
}
.AuthModalLogo-image {
  display: inline-block;
  width: 80%;
  max-width: 462px;
  padding-top: 30px;
}

.AuthModalTitle {
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: minmax(32px, 1fr) auto minmax(32px, 1fr);
  gap: 32px;
  margin: 24px 0;
}
@media (max-width: 767px) {
  .AuthModalTitle {
    display: flex;
    justify-content: center;
  }
}
.AuthModalTitle-lines {
  height: 4px;
  border-top: solid 1px #FFFFFF;
  border-bottom: solid 1px #FFFFFF;
}
@media (max-width: 767px) {
  .AuthModalTitle-lines {
    display: none;
  }
}
.AuthModalTitle-content {
  text-align: center;
  font-family: var(--font-family-subtitle, "Roboto", Arial, Helvetica, sans-serif);
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 1.4;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .AuthModalTitle-content {
    font-size: 1.222rem;
  }
}
@media (max-width: 767px) {
  .AuthModalTitle-content {
    text-transform: none;
  }
}

.AuthPlatforms {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  padding: 20px 0px;
}
@media (min-width: 768px) {
  .AuthPlatforms {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 1280px) {
  .AuthPlatforms--triple {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.AuthPageContainer {
  width: 80%;
  max-width: 640px;
  margin: 0 auto;
}

.AuthRememberRow {
  display: flex;
  flex-flow: row wrap;
  gap: 4px 16px;
  margin-bottom: 16px;
}
.AuthRememberRow-remember, .AuthRememberRow-reset {
  font-size: 16px;
}
.AuthRememberRow-remember {
  text-align: left;
  display: flex;
  flex-flow: row nowrap;
  gap: 4px;
  align-items: center;
}
.AuthRememberRow-remember input {
  display: inline-block;
  margin: 0 4px 0 0 !important;
  top: 0 !important;
  float: none !important;
}
.AuthRememberRow-reset {
  text-align: right;
  flex: 1 1 auto;
}
@media (max-width: 600px) {
  .AuthRememberRow {
    flex-direction: column;
  }
  .AuthRememberRow .AuthRememberRow-reset {
    text-align: center;
    padding-top: 20px;
  }
}

#submit-btn {
  text-align: center;
}

@media (max-width: 1050px) {
  form [data-tip]::before, form [data-tip]::after {
    display: none !important;
  }
}
/* Used to set the old line-height of 1.2 so legacy pages won't break. */
.LegacyPageContainer {
  line-height: 1.2;
}

.AnalyticsDebug {
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  height: 50vh;
  width: 100%;
  max-width: 860px;
  padding: 32px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #FFFFFF;
  overflow-y: auto;
  z-index: 200;
}
.AnalyticsDebug.is-active {
  display: block;
}
.AnalyticsDebug-title {
  text-align: center;
  margin-bottom: 16px;
}
.AnalyticsDebug-table {
  position: relative;
  font-size: 0.875rem;
  overflow: hidden;
}
.AnalyticsDebug-row {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 16px;
  cursor: pointer;
}
.AnalyticsDebug-row.is-direct .AnalyticsDebug-index {
  color: #C8C8C8;
}
.AnalyticsDebug-row.is-direct:hover {
  color: #DDC57D;
}
.AnalyticsDebug-row.is-collapsed {
  max-height: 30px;
  overflow: hidden;
}
.AnalyticsDebug-row.is-collapsed .AnalyticsDebug-toggle:after {
  content: "+";
}
.AnalyticsDebug-row:nth-child(2n) {
  background-color: rgba(255, 255, 255, 0.1);
}
.AnalyticsDebug-row:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #66C866;
}
.AnalyticsDebug-toggle {
  position: relative;
  font-weight: bold;
  display: inline-block;
  text-align: center;
  width: 28px;
  height: 28px;
  border: solid 1px #FFFFFF;
  opacity: 0.5;
}
.AnalyticsDebug-toggle:hover {
  opacity: 1;
}
.AnalyticsDebug-toggle:after {
  content: "-";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.AnalyticsDebug-inner {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}
@media (min-width: 768px) {
  .AnalyticsDebug-inner {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.AnalyticsDebug-info {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 8px;
}
.AnalyticsDebug-index {
  font-weight: bold;
  color: #66C866;
}
.AnalyticsDebug-element {
  font-weight: bold;
}
.AnalyticsDebug-source {
  grid-column: span 3;
  font-style: italic;
}
.AnalyticsDebug-payload {
  font-family: "Courier New", monospace;
  font-size: 0.75rem;
  word-break: break-all;
}