.PackSelectorItem {
    position: relative;
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    align-items: center;
    gap: $SPACE_SMALL;
    padding: var(--padding-pack-selector, 16px 24px);

    line-height: $LINE_HEIGHT_BASE;
    font-weight: var(--font-weight-pack-selector, normal);
    color: var(--color-pack-selector-text, #{$COLOR_TEXT});
    background: var(--background-pack-selector, none);
    border-radius: $SPACE_SMALL;
    transition: all $TRANSITION_FAST;

    cursor: pointer;

    @include breakpoint-desktop-only {
        grid-template-columns: minmax(0, 1fr);
    }

    &:after {
        content: "";
        @include FULL_COVER;
        border: solid 1px var(--color-pack-selector-border, #{$COLOR_TEXT});
        border-radius: $SPACE_SMALL;
        transition: all $TRANSITION_FAST;
        pointer-events: none;
    }

    &-bullet {
        display: inline-block;
        width: 18px;
        aspect-ratio: 1;
        border-radius: 50%;
        border: solid 1px var(--color-pack-selector-bullet-border, #{$COLOR_TEXT});
        background: var(--background-pack-selector-bullet, none);

        @include breakpoint-desktop-only {
            display: none;
        }
    }
    &-label {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        white-space: nowrap;

        @include breakpoint-desktop-up {
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        @media (max-width: 1919px) {
            flex-flow: column nowrap;
            align-items: flex-start;
        }
    }
    &-title {
        flex: 1 0 auto;
    }
    &-value {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: $SPACE_SMALLER;
    }

    &:hover,
    &.is-active {
        font-weight: var(--font-weight-pack-selector-active, normal);
        color: var(--color-pack-selector-text-active, #{$COLOR_INVERTED_TEXT});
        background: var(--background-pack-selector-active, #{$COLOR_ACCENT});
        &:after {
            border-color: var(--color-pack-selector-border-active, #{$COLOR_ACCENT});
        }
        .PackSelectorItem {
            &-bullet {
                border-color: var(--color-pack-selector-bullet-border-active, #{$COLOR_INVERTED_TEXT});
            }
        }
    }
    &.is-active {
        .PackSelectorItem {
            &-bullet {
                background: var(--background-pack-selector-bullet-active, #{$COLOR_INVERTED_TEXT});
            }
        }
    }
}
