.SubNavigationDropdown {
    position: relative;
    padding: $SPACE_MEDIUM - $SPACE_SMALLER 0;
    margin: 0 $SPACE_SMALL;

    &:hover {
        .SubNavigationDropdown {
            &-label {
                background-color: #3F5149;
                border-radius: 4px;
            }

            &-dropdown {
                display: block;
            }
        }
    }

    &-label {
        padding: $SPACE_SMALLER $SPACE_MEDIUM;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: $SPACE_SMALL;

        .SubNavigationItem-tag {
            margin: 0;
        }
    }

    &-icon {
        filter: invert(1);
    }

    &-dropdown {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        padding-top: $SPACE_SMALL;
    }

    &-content {
        margin: 0;
        padding: $SPACE_MEDIUM + $SPACE_SMALL;
        list-style: none;
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: $SPACE_SMALL + $SPACE_SMALLER;
        background-color: black;
        border-radius: 8px;

        .SubNavigationItem {
            display: flex;
            gap: $SPACE_SMALLER;

            &-link {
                padding: 0;
                margin: 0;
            }
        }
    }

    &.is-collapsed {
        display: none;
        padding: 0;
        margin: 0;

        .SubNavigationDropdown {
            &-icon {
                display: none;
            }
        }
    }

    &.is-collapsed.is-open {
        display: block;
        width: 100%;

        .SubNavigationDropdown {
            &-label {
                padding: $SPACE_MEDIUM;
            }

            &-dropdown {
                display: block;
                position: static;
            }

            &-content {
                background-color: #232B27;
                border-radius: 0;
                padding: 0;
                gap: 0;
            }
        }
    }

    .SubNavigationItem-tag {
        font-weight: $FONT_WEIGHT_HYPE;
    }
}