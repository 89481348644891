.SubNavigationItem {
    position: relative;
    margin: 0;

    &-tag {
        padding: $SPACE_SMALLER $SPACE_SMALL;
        border-radius: $SPACE_SMALLER;
        margin-left: $SPACE_MEDIUM;

        &--new {
            display: inline-block;
            background-color: $COLOR_GOLD;
            color: $COLOR_TEXT;
            @include BADGE_STYLES;
        }

        &--sale {
            color: $COLOR_INVERTED_TEXT;
            display: inline-flex;
            @include SALE_GRADIENT;
        }
    }

    &-link {
        position: relative;
        display: inline-flex;
        // padding: $SPACE_MEDIUM;
        margin: $SPACE_MEDIUM;
        --color-link: transparent;
        --color-link-hover: transparent;
        font-weight: 700;

        &:after {
            content: attr(data-label);
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);

            color: var(--color-subnavigation-link, #{$COLOR_INVERTED_TEXT});
            font-weight: 400;
        }

        &:hover {
            &:after {
                color: var(--color-subnavigation-link-hover, #{$COLOR_GOLD});
                font-weight: 700;
            }
        }
    }

    &--button {
        display: flex;
        align-items: center;
        margin: $SPACE_BASE;
    }

    &--dropdown {
        &.is-collapsed {
            padding: $SPACE_MEDIUM;
        }

        &.is-collapsed.is-active {
            background-color: #3F5149;
        }
    }

    &.is-active {
        .SubNavigationItem-link:after {
            font-weight: 700;
        }
    }
}